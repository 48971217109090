import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Drawer from '../atoms/navigation/Drawer'
import SiteHeader from './SiteHeader'
import { FlexColumn, FlexRow } from '../atoms/layout/Flex'
import {
  useMoneyspaceCreateDialog,
  useMoneyspaceDeleteDialog,
  useMoneyspaceEditDialog,
  useMoneyspaceFolderMoveDialog,
  useMoneyspaceGroupCreateDialog,
  useMoneyspaceGroupDeleteDialog,
  useMoneyspaceGroupEditDialog,
  useMoneyspaceMoveDialog,
} from '../../hooks/useDialog'
import IconButton from '../atoms/inputs/IconButton'
import {
  DashboardIcon,
  DeleteIcon,
  EditIcon,
  ImportIcon,
  MoneyspaceAddIcon,
  MoneyspaceGroupIcon,
  MoveIcon,
  SearchIcon,
} from '../atoms/display/Icons'
import { Moneyspace, MoneyspaceFolder } from '../../types/Moneyspace'
import useMoneyspace from '../../hooks/useMoneyspace'
import Divider from '../atoms/display/Divider'
import usePermission from '../../hooks/usePermission'
import { SessionSelectors } from '../../store/session/selectors'
import List from '../atoms/display/List'
import ListItem from '../atoms/display/ListItem'
import useRouter from '../../hooks/useRouter'
import useSidebar from '../../hooks/useSidebar'
import { useDeviceType } from '../../hooks/useDeviceType'
import MoneyspaceTreeView from './moneyspace/MoneyspaceTreeView'
import MoneyspaceSearchForm from './moneyspace/MoneyspaceSearchForm'
import Label from '../atoms/display/Label'
import useConfig from '../../hooks/useConfig'

type SiteNavBarProps = {}

export default function SiteNavBar({}: SiteNavBarProps) {
  const { moneyspacePermissions, moneyspaceFolderPermissions, transactionPermissions } = usePermission()
  const { state, close } = useSidebar()
  const { pc } = useDeviceType()
  const user = useSelector(SessionSelectors.user)
  const [filteredMoneyspaces, setFilteredMoneyspaces] = useState<Moneyspace[] | null>(null)
  const moneyspaceGroupCreateDialog = useMoneyspaceGroupCreateDialog()
  const moneyspaceGroupEditDialog = useMoneyspaceGroupEditDialog()
  const moneyspaceFolderMoveDialog = useMoneyspaceFolderMoveDialog()
  const moneyspaceGroupDeleteDialog = useMoneyspaceGroupDeleteDialog()
  const moneyspaceCreateDialog = useMoneyspaceCreateDialog()
  const moneyspaceEditDialog = useMoneyspaceEditDialog()
  const moneyspaceMoveDialog = useMoneyspaceMoveDialog()
  const moneyspaceDeleteDialog = useMoneyspaceDeleteDialog()
  const { pathname } = useLocation()
  const { moneyspaceId } = useParams()
  const { find, currentFolder, pinnedMoneyspaces } = useMoneyspace()
  const selectedMoneyspace = find(moneyspaceId)
  const [selectedFolder, setSelectedFolder] = useState<MoneyspaceFolder | null>(null)
  const { root, search, toolsTransactionImporter } = useRouter()
  const { enableTransactionImport } = useConfig()

  return (
    <Drawer open={state} onClose={close} variant={pc ? 'persistent' : 'temporary'}>
      <FlexColumn width={320} justify="space-between" grow>
        <FlexColumn>
          <SiteHeader size="small" position="static" />
          <List>
            <ListItem label="ダッシュボード" icon={<DashboardIcon />} selected={pathname === '/'} onClick={root} />
            <ListItem
              label="検索"
              icon={<SearchIcon />}
              selected={pathname.startsWith('/search')}
              onClick={() => search()}
            />
            {enableTransactionImport && (
              <ListItem
                label="一括インポート"
                icon={<ImportIcon />}
                disabled={!transactionPermissions.create(user)}
                selected={pathname.startsWith('/tools/transaction_importer')}
                onClick={() => toolsTransactionImporter()}
              />
            )}
          </List>
          <FlexRow px={2} height={40} scroll={false}>
            <IconButton
              label="フォルダ作成"
              disabled={!moneyspaceFolderPermissions.create(user)}
              onClick={() => moneyspaceGroupCreateDialog.open(currentFolder(selectedFolder, selectedMoneyspace))}
            >
              <MoneyspaceGroupIcon />
            </IconButton>
            <IconButton
              label="マネースペース作成"
              onClick={() => moneyspaceCreateDialog.open(currentFolder(selectedFolder, selectedMoneyspace))}
              disabled={!moneyspacePermissions.create(user)}
            >
              <MoneyspaceAddIcon />
            </IconButton>
            {selectedFolder && (
              <>
                <IconButton
                  label="フォルダ編集"
                  disabled={!moneyspaceFolderPermissions.edit(user)}
                  onClick={() => moneyspaceGroupEditDialog.open(selectedFolder)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  label="フォルダ移動"
                  disabled={!moneyspaceFolderPermissions.edit(user)}
                  onClick={() => moneyspaceFolderMoveDialog.open(selectedFolder)}
                >
                  <MoveIcon />
                </IconButton>
                <IconButton
                  label="フォルダ削除"
                  onClick={() => moneyspaceGroupDeleteDialog.open(selectedFolder)}
                  disabled={
                    selectedFolder.msIds.length > 0 ||
                    selectedFolder.children.length > 0 ||
                    !moneyspaceFolderPermissions.delete(user)
                  }
                  color="caution"
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
            {selectedFolder === null && selectedMoneyspace && (
              <>
                <IconButton
                  label="マネースペース編集"
                  onClick={() => moneyspaceEditDialog.open(selectedMoneyspace)}
                  disabled={!moneyspacePermissions.edit(selectedMoneyspace, user)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  label="マネースペース移動"
                  disabled={!moneyspacePermissions.move(user)}
                  onClick={() => moneyspaceMoveDialog.open(selectedMoneyspace)}
                >
                  <MoveIcon />
                </IconButton>
                <IconButton
                  label="マネースペース削除"
                  onClick={() => moneyspaceDeleteDialog.open(selectedMoneyspace)}
                  disabled={!moneyspacePermissions.delete(selectedMoneyspace, user)}
                  color="caution"
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </FlexRow>
          <FlexColumn space={0}>
            {pinnedMoneyspaces.length > 0 && (
              <>
                <Divider />
                <FlexColumn scroll={false}>
                  <FlexRow px={1} pt={1} scroll={false}>
                    <Label text="ピン留めしたマネースペース" variant="caption" />
                  </FlexRow>
                  <FlexColumn maxHeight={176} grow>
                    <MoneyspaceTreeView
                      initialSelectedId={moneyspaceId}
                      filteredMoneyspaces={pinnedMoneyspaces}
                      onSelectFolder={(folder) => setSelectedFolder(folder ?? null)}
                    />
                  </FlexColumn>
                </FlexColumn>
              </>
            )}
            <Divider />
            <FlexRow pa={1} scroll={false}>
              <MoneyspaceSearchForm onSearch={setFilteredMoneyspaces} />
            </FlexRow>
            {filteredMoneyspaces ? (
              <MoneyspaceTreeView
                initialSelectedId={moneyspaceId}
                filteredMoneyspaces={filteredMoneyspaces}
                onSelectFolder={(folder) => setSelectedFolder(folder ?? null)}
              />
            ) : (
              <MoneyspaceTreeView
                initialSelectedId={moneyspaceId}
                onSelectFolder={(folder) => setSelectedFolder(folder ?? null)}
              />
            )}
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </Drawer>
  )
}
