import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import RadioGroup from '../../atoms/inputs/RadioGroup'
import {
  InvoiceLessPatternB,
  MembershipFeeCondition,
  MembershipFeeType,
  MembershipTaxIn,
} from '../../../types/Moneyspace'
import CompanyMembershipFlatFee from './CompanyMembershipFlatFee'
import CompanyMembershipRangeFee from './CompanyMembershipRangeFee'
import Button from '../../atoms/inputs/Button'
import FormItem from '../../molecules/inputs/FormItem'
import RadioControl from '../../atoms/inputs/RadioControl'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import { CompanyActions } from '../../../store/company'
import { CompanySelectors } from '../../../store/company/selectors'
import { SessionSelectors } from '../../../store/session/selectors'
import usePermission from '../../../hooks/usePermission'
import Alert from '../../atoms/feedback/Alert'

export type MembershipFeeParams = {
  taxIn: MembershipTaxIn
  type: MembershipFeeType
  conditions: MembershipFeeConditionParams[]
  enableMaximumFee: boolean
  maximumFee: number
  roundingType: number
}

export type MembershipFeeConditionParams = {
  upperAmount: number
  amount: number
  rate: number
}

function membershipType(conditions?: MembershipFeeCondition[]) {
  if (conditions === undefined) {
    return 1
  }
  if (conditions.length === 0) {
    return 1
  }
  if (conditions.length === 1) {
    return 2
  }
  return 3
}

export default function CompanyTabItemMembership() {
  const membershipFee = useSelector(CompanySelectors.membershipFee)
  const membershipFeeName = useSelector(CompanySelectors.membershipFeeName)
  const company = useSelector(CompanySelectors.company)
  const [type, setType] = useState<number>(membershipType(membershipFee?.conditions))
  const [membershipFeeType, setMembershipFeeType] = useState<number>(membershipFee.type)
  const typeItems = [
    { value: 1, label: `${membershipFeeName}なし` },
    { value: 2, label: '請求金額に対して一律で設定' },
    { value: 3, label: '請求金額の範囲を指定して設定' },
  ]
  const user = useSelector(SessionSelectors.user)
  const { companyPermissions } = usePermission()
  const disabled = !companyPermissions.edit(user)
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    conditions: rules.membershipFeeConditions,
  })
  const methods = useForm<MembershipFeeParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      taxIn: membershipFee?.taxIn ?? 1,
      type: membershipFee?.type ?? 1,
      conditions: membershipFee?.conditions.map((item) => ({
        upperAmount: item.amount ?? 0,
        amount: item.value,
        rate: item.value,
      })),
      enableMaximumFee: membershipFee?.maximumFee !== null,
      maximumFee: membershipFee?.maximumFee ?? 0,
      roundingType: membershipFee?.roundingType ?? 1,
    },
  })
  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'conditions' })
  const { watch, control } = methods
  useEffect(() => {
    watch((values, { name }) => {
      if (name === 'type') {
        setMembershipFeeType(Number(values.type))
      }
    })
  }, [watch])
  const handleChangeType = useCallback(
    (newType) => {
      const typeValue = Number(newType)
      setType(typeValue)
      const { length } = fields
      for (let i = 0; i < length; i += 1) {
        remove(0)
      }
      if (typeValue === 2 || typeValue === 3) {
        append({ upperAmount: 0, amount: 0, rate: 0 })
      }
    },
    [append, fields, remove]
  )
  const handleSave = useCallback(
    (values: MembershipFeeParams) => {
      const conditions: MembershipFeeCondition[] = values.conditions.map((item) => ({
        amount: item.upperAmount,
        value: Number(values.type) === 1 ? item.amount : item.rate,
      }))
      dispatch(
        CompanyActions.saveMembershipFee({
          membershipFee: {
            name: membershipFeeName,
            taxIn: Number(values.taxIn) as MembershipTaxIn,
            type: Number(values.type) as MembershipFeeType,
            conditions,
            maximumFee: type === 2 && values.enableMaximumFee ? values.maximumFee : null,
            roundingType: values.roundingType,
          },
        })
      )
    },
    [dispatch, membershipFeeName, type]
  )
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleSave)}>
        <FlexColumn>
          <RadioGroup value={type} items={typeItems} onChange={handleChangeType} disabled={disabled} />
          {type !== 1 && (
            <FlexRow>
              <FormItem label="請求金額">
                <RadioControl<MembershipFeeParams>
                  name="taxIn"
                  control={methods.control}
                  items={[
                    { value: 1, label: '税抜き' },
                    { value: 2, label: '税込み' },
                  ]}
                  row
                  disabled={disabled || (type === 2 && membershipFeeType === 1)}
                />
              </FormItem>
              <FormItem label={`${membershipFeeName}額`}>
                <RadioControl<MembershipFeeParams>
                  name="type"
                  control={methods.control}
                  items={[
                    { value: 1, label: '金額を入力' },
                    { value: 2, label: '料率を入力' },
                  ]}
                  row
                  disabled={disabled}
                />
              </FormItem>
            </FlexRow>
          )}
          {type === 2 && <CompanyMembershipFlatFee />}
          {type === 3 && <CompanyMembershipRangeFee />}
          {membershipFeeType === 2 && (
            <FormItem label="小数点以下の端数処理">
              <RadioControl
                items={[
                  { value: 1, label: '切り捨て' },
                  { value: 2, label: '切り上げ' },
                  { value: 3, label: '四捨五入' },
                ]}
                name="roundingType"
                control={control}
                row
              />
            </FormItem>
          )}
          <Button type="submit" disabled={disabled}>
            保存
          </Button>
          {company && company.invoiceLessActionType === InvoiceLessPatternB && (
            <FlexRow>
              <Alert
                severity="warning"
                title="インボイス対応で「取引先の消費税の請求を不可とする」を選択した場合"
                label={`受注者が事業者登録番号未登録の場合は、「税抜き」請求金額に対して${membershipFeeName}の計算がされます`}
              />
            </FlexRow>
          )}
        </FlexColumn>
      </Form>
    </FormProvider>
  )
}
