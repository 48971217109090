import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import request, { Request } from '../repositories/Request'
import systemSlice, { initialSystemState } from './system'
import sessionSlice, { initialSessionState } from './session'
import moneyspacesSlice, { initialMoneyspacesState } from './moneyspaces'
import dialogsSlice, { initialDialogsState } from './dialogs'
import documentsSlice, { initialDocumentsState } from './documents'
import companySlice, { initialCompanyState } from './company'
import transactionsSlice, { initialTransactionsState } from './transactions'
import dashboardSlice, { initialDashboardState } from './dashboard'
import approvalFlowSlice, { initialApprovalFlowState } from './approval_flow'
import chatSlice, { initialChatState } from './chat'
import toolsSlice, { initialToolsState } from './tools'
import registerSlice, { initialRegisterState } from './register'
import jobSlice, { initialJobState } from './job'

const rootReducer = combineReducers({
  system: systemSlice.reducer,
  session: sessionSlice.reducer,
  register: registerSlice.reducer,
  moneyspaces: moneyspacesSlice.reducer,
  transactions: transactionsSlice.reducer,
  documents: documentsSlice.reducer,
  company: companySlice.reducer,
  dialogs: dialogsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  approvalFlow: approvalFlowSlice.reducer,
  chat: chatSlice.reducer,
  tools: toolsSlice.reducer,
  job: jobSlice.reducer,
})

const preloadedState = () => ({
  system: initialSystemState,
  session: initialSessionState,
  register: initialRegisterState,
  moneyspaces: initialMoneyspacesState,
  transactions: initialTransactionsState,
  documents: initialDocumentsState,
  company: initialCompanyState,
  dialogs: initialDialogsState,
  dashboard: initialDashboardState,
  approvalFlow: initialApprovalFlowState,
  chat: initialChatState,
  tools: initialToolsState,
  job: initialJobState,
})

export type StoreState = ReturnType<typeof preloadedState>

export type ThunkExtra = {
  request: Request
}

const thunkExtra: ThunkExtra = {
  request,
}

const persistConfig = {
  key: 'session',
  storage,
  whitelist: ['session'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: thunkExtra },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      createLogger({
        diff: true,
        collapsed: true,
      })
    ),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: preloadedState(),
})

export const persistor = persistStore(store)
export default store
