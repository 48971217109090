import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { MoneyspacesSelectors } from '../store/moneyspaces/selectors'
import { Moneyspace, MoneyspaceFolder } from '../types/Moneyspace'
import { User } from '../types/User'
import { TransactionSelectors } from '../store/transactions/selectors'
import { CompanyTypeRS } from '../types/Company'

function findFolder(moneyspace: Moneyspace, folders: MoneyspaceFolder[]): MoneyspaceFolder | null {
  const result1 = folders.find((item) => item.msIds.includes(moneyspace.id))
  if (result1) {
    return result1
  }
  for (let i = 0; i < folders.length; i += 1) {
    const folder = folders[i]
    const result2 = findFolder(moneyspace, folder.children)
    if (result2) {
      return result2
    }
  }
  return null
}

export default function useMoneyspace() {
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const folders = useSelector(MoneyspacesSelectors.folders)
  const members = useSelector(MoneyspacesSelectors.members)
  const factoringTransactions = useSelector(TransactionSelectors.factoringTransactions)
  const find = useCallback((id) => moneyspaces.find((moneyspace) => moneyspace.id === id), [moneyspaces])
  const getFactoringUrl = useCallback(
    (id) => {
      if (factoringTransactions) {
        const document = factoringTransactions.find((transaction) => transaction.moneyspace?.id === id)
        if (document) {
          return document.factoringUrl
        }
      }
      return undefined
    },
    [factoringTransactions]
  )
  const isContractee = useCallback(
    (moneyspace: Moneyspace, user?: User) => moneyspace.contractee?.id === user?.companyId,
    []
  )
  const isRS = useCallback((moneyspace?: Moneyspace) => {
    if (moneyspace && moneyspace.contractee) {
      return moneyspace.contractee.type === CompanyTypeRS
    }
    return false
  }, [])
  const findMembers = useCallback(
    (id: string) => {
      const moneyspaceMembers = members.find((item) => item.moneyspaceId === id)
      if (moneyspaceMembers) {
        return moneyspaceMembers.users
      }
      return []
    },
    [members]
  )
  const currentFolder = useCallback(
    (folder?: MoneyspaceFolder | null, moneyspace?: Moneyspace) => {
      if (folder) {
        return folder
      }
      if (moneyspace) {
        return findFolder(moneyspace, folders)
      }
      return null
    },
    [folders]
  )
  const pinnedMoneyspaces = moneyspaces.filter((item) => item.isPinned)
  return {
    find,
    isContractee,
    isRS,
    findMembers,
    getFactoringUrl,
    currentFolder,
    pinnedMoneyspaces,
  }
}
