import { JobOffer } from '../../../types/job'
import Label from '../../atoms/display/Label'

type Props = {
  offer: JobOffer
}

function text(offer: JobOffer) {
  switch (offer.publishedStatus) {
    case 0:
      return '非公開'
    case 1:
      return '公開中'
    default:
      return '非公開'
  }
}

function color(offer: JobOffer) {
  switch (offer.publishedStatus) {
    case 0:
      return 'warning.main'
    case 1:
      return 'primary'
    default:
      return 'warning.main'
  }
}

export default function JobOfferPublishedStatusView({ offer }: Props) {
  return <Label text={text(offer)} color={color(offer)} />
}
