import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useJobImageDeleteDialog, useJobImageSettingDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import FileSelectButton from '../../atoms/inputs/FileSelectButton'
import { JobActions } from '../../../store/job'
import { JobSelectors } from '../../../store/job/selectors'
import Image from '../../atoms/display/Image'

export default function JobImageSettingDialog() {
  const { state, close } = useJobImageSettingDialog()
  const dispatch = useDispatch()
  const images = useSelector(JobSelectors.images)
  const jobImageDeleteDialog = useJobImageDeleteDialog()

  const handleSelectFile = useCallback(
    (files: File[]) => {
      dispatch(JobActions.uploadImage({ file: files[0] }))
    },
    [dispatch]
  )

  return (
    <Dialog open={state !== undefined} title="求人画像設定" width="xs" onClose={close}>
      <FlexColumn maxHeight="60vh">
        <FileSelectButton accepts={['image/*']} onSelect={handleSelectFile}>
          アップロード
        </FileSelectButton>
        <FlexColumn space={2}>
          {images.length === 0 && <p>画像が登録されていません</p>}
          {images.map((image) => (
            <FlexRow align="center" scroll={false}>
              <Image url={image.url} alt={image.fileName} width={150} height={94} />
              <FlexColumn>
                <Button size="sm" height="small" color="caution" onClick={() => jobImageDeleteDialog.open(image.id)}>
                  削除
                </Button>
              </FlexColumn>
            </FlexRow>
          ))}
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}
