import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import SectionPanel from '../components/molecules/SectionPanel'
import RadioControl from '../components/atoms/inputs/RadioControl'
import FormItem from '../components/molecules/inputs/FormItem'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import TextField from '../components/atoms/inputs/TextField'
import Label from '../components/atoms/display/Label'
import Button from '../components/atoms/inputs/Button'
import Form from '../components/atoms/inputs/Form'
import Pagination from '../components/atoms/navigation/Pagination'
import Table from '../components/atoms/display/Table'
import TableRow from '../components/atoms/display/TableRow'
import TableCell from '../components/atoms/display/TableCell'
import { TransactionSelectors } from '../store/transactions/selectors'
import { TransactionActions } from '../store/transactions'
import useDay from '../hooks/useDay'
import useRouter from '../hooks/useRouter'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import { TransactionSearchResultItem } from '../types/transaction'
import Checkbox from '../components/atoms/inputs/Checkbox'
import DeliveredTransactionImportFailuresDialog from '../components/organisms/transaction/DeliveredTransactionImportFailuresDialog'

type DeliveredTransactionSearchParams = {
  differencePriceType: number
  externalId?: string
  msName?: string
}

export default function DeliveredTransactionsPage() {
  const { changeTitle } = usePage()
  const { formatDate, fromNow, timestamp } = useDay()
  const { deliveredTransactions } = useRouter()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const type = Number(query.get('type') ?? 2)
  const code = query.get('code') ?? undefined
  const msName = query.get('ms') ?? undefined
  const page = Number(query.get('page') ?? 1)
  const transactions = useSelector(TransactionSelectors.deliveredTransactions)
  const failures = useSelector(TransactionSelectors.importFailureDeliveredTransactions)
  const { transaction: transactionPage } = useRouter()
  const dispatch = useDispatch()
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [openImportErrorDialog, setOpenImportErrorDialog] = useState<boolean>(false)

  const form = useForm<DeliveredTransactionSearchParams>({
    defaultValues: {
      differencePriceType: type,
      externalId: code,
      msName,
    },
  })

  useEffect(() => {
    changeTitle('完納')
  }, [changeTitle])

  useEffect(() => {
    dispatch(
      TransactionActions.loadUDeliveredTransactions({
        page,
        differencePriceType: type === 2 ? undefined : type,
        externalId: code,
        msName,
        pageSize: 100,
      })
    )
    window.scrollTo(0, 0)
  }, [dispatch, page, type, code, msName])

  const handleSearch = useCallback(
    (params: DeliveredTransactionSearchParams) => {
      deliveredTransactions(1, params.differencePriceType, params.externalId, params.msName)
    },
    [deliveredTransactions]
  )

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      deliveredTransactions(pageNumber, type, code, msName)
    },
    [deliveredTransactions, type, code, msName]
  )

  const handleDownloadCsv = useCallback(() => {
    dispatch(
      TransactionActions.downloadDeliveredTransactions({
        filename: `transactions_${timestamp()}.csv`,
        differencePriceType: type,
        externalId: code,
        msName,
      })
    )
  }, [code, dispatch, msName, timestamp, type])

  const handleClickTransaction = useCallback(
    (transaction: TransactionSearchResultItem) => {
      transactionPage(transaction.id)
    },
    [transactionPage]
  )

  const handleCheck = useCallback(
    (id: string) => {
      if (checkedIds.includes(id)) {
        setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id))
      } else {
        setCheckedIds([...checkedIds, id])
      }
    },
    [checkedIds]
  )

  const handleCheckAll = useCallback(() => {
    if (checkedIds.length === 0) {
      setCheckedIds(transactions.results.map((result) => result.id))
    } else {
      setCheckedIds([])
    }
  }, [checkedIds.length, transactions.results])

  const handleImport = useCallback(
    (transactionId: string) => {
      dispatch(
        TransactionActions.importDeliveredTransactions({
          transcriptionIds: [transactionId],
          differencePriceType: type,
          clientId: code,
          msName,
          pageSize: 100,
        })
      )
    },
    [code, dispatch, msName, type]
  )

  const handleSelectedImport = useCallback(() => {
    dispatch(
      TransactionActions.importDeliveredTransactions({
        transcriptionIds: checkedIds,
        differencePriceType: type,
        clientId: code,
        msName,
        pageSize: 100,
      })
    )
  }, [checkedIds, code, dispatch, msName, type])

  const handleOpenImportErrorDialog = useCallback(() => {
    setOpenImportErrorDialog(true)
  }, [])

  return (
    <FlexColumn>
      <SectionPanel title="検索条件" size="full">
        <Form onSubmit={form.handleSubmit(handleSearch)}>
          <FlexColumn>
            <FlexRow space={4}>
              <Label text="現在のフェーズ: 完納" />
              <Label text="ステータス: ナサホーム確認中" />
            </FlexRow>
            <FormItem label="過不足金額">
              <RadioControl
                control={form.control}
                name="differencePriceType"
                items={[
                  { value: 2, label: '全て' },
                  { value: 1, label: 'あり' },
                  { value: 0, label: 'なし' },
                ]}
                row
              />
            </FormItem>
            <FormItem label="仕入先コード">
              <TextField register={form.register('externalId')} size="md" />
            </FormItem>
            <FormItem label="マネースペース名">
              <TextField register={form.register('msName')} size="md" />
            </FormItem>
            <FlexRow>
              <Button type="submit">検索</Button>
            </FlexRow>
          </FlexColumn>
        </Form>
      </SectionPanel>
      {transactions.count === 0 ? (
        <Label text="完納フェーズの取引はありません" />
      ) : (
        <>
          <FlexRow>
            <Button size="lg" disabled={checkedIds.length === 0} onClick={handleSelectedImport}>
              選択した取引の取り込み
            </Button>
            {failures.length > 0 && (
              <FlexRow align="center">
                <Label text={`${failures.length}件の取り込みに失敗しました`} color="error" />
                <Button size="sm" onClick={handleOpenImportErrorDialog}>
                  詳細
                </Button>
              </FlexRow>
            )}
          </FlexRow>
          <FlexRow justify="space-between">
            <PaginationCountLabel page={page} pageSize={100} contents={transactions} />
            <Button variant="outlined" onClick={handleDownloadCsv}>
              CSVダウンロード
            </Button>
          </FlexRow>
          <Table
            header={
              <TableRow>
                <TableCell header size="xxs">
                  <Checkbox
                    checked={checkedIds.length === transactions.results.length}
                    indeterminate={checkedIds.length > 0 && checkedIds.length < transactions.results.length}
                    onChange={() => handleCheckAll()}
                  />
                </TableCell>
                <TableCell header size="sm">
                  マネースペース
                </TableCell>
                <TableCell header size="xs">
                  注文No.
                </TableCell>
                <TableCell header size="sm">
                  取引名
                </TableCell>
                <TableCell header size="sm">
                  金額
                </TableCell>
                <TableCell header size="xs">
                  過不足金額
                </TableCell>
                <TableCell header size="xs">
                  工事期間
                </TableCell>
                <TableCell header size="xs">
                  担当者
                </TableCell>
                <TableCell header size="xs">
                  店舗名
                </TableCell>
                <TableCell header size="xs">
                  発注者
                </TableCell>
                <TableCell header size="xs">
                  受注者
                </TableCell>
                <TableCell header size="xs">
                  最終更新
                </TableCell>
                <TableCell header size="sm" />
              </TableRow>
            }
          >
            {transactions.results.map((result) => (
              <TableRow onClick={() => handleClickTransaction(result)}>
                <TableCell size="xxs">
                  <Checkbox checked={checkedIds.includes(result.id)} onChange={() => handleCheck(result.id)} />
                </TableCell>
                <TableCell size="sm">{result.moneyspace.name}</TableCell>
                <TableCell size="xs">{result.optional?.clientAppId}</TableCell>
                <TableCell size="sm">{result.name}</TableCell>
                <TableCell size="sm" align="right">
                  <Label
                    text={result.details.map((detail) => detail.amount).reduce((value1, value2) => value1 + value2, 0)}
                    format="amount"
                  />
                </TableCell>
                <TableCell size="xs" align="right">
                  {result.optional?.differencePrice !== undefined && (
                    <Label text={result.optional.differencePrice} format="amount" />
                  )}
                </TableCell>
                <TableCell size="xs">
                  {result.deliveryDateFrom ||
                    (result.deliveryDateTo && (
                      <>
                        {result.deliveryDateFrom && formatDate(result.deliveryDateFrom)}
                        {result.deliveryDateTo && <>〜{formatDate(result.deliveryDateTo)}</>}
                      </>
                    ))}
                </TableCell>
                <TableCell size="xs">
                  <Label text={result.optional?.rsUserName ?? ''} />
                </TableCell>
                <TableCell size="xs">
                  <Label text={result.optional?.branchName ?? ''} />
                </TableCell>
                <TableCell size="xs">{result.moneyspace.contractee?.name}</TableCell>
                <TableCell size="xs">{result.moneyspace.contractor?.name}</TableCell>
                <TableCell size="xs" align="center">
                  {fromNow(result.updatedAt)}
                </TableCell>
                <TableCell size="sm">
                  <Button onClick={() => handleImport(result.id)}>取り込み</Button>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <Pagination count={transactions.count} page={page} size={100} onChange={handleChangePage} />
        </>
      )}
      <DeliveredTransactionImportFailuresDialog
        open={openImportErrorDialog}
        onClose={() => setOpenImportErrorDialog(false)}
      />
    </FlexColumn>
  )
}
