import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn } from '../../components/atoms/layout/Flex'
import TransactionForm from '../../components/organisms/transaction/TransactionForm'
import usePage from '../../hooks/usePage'
import { TransactionActions } from '../../store/transactions'
import { MoneyspacesActions } from '../../store/moneyspaces'
import { TransactionSelectors } from '../../store/transactions/selectors'
import useConsts from '../../hooks/useConsts'
import useMoneyspace from '../../hooks/useMoneyspace'
import TransactionRSForm from '../../components/organisms/transaction/TransactionRSForm'

export default function TransactionEditPage() {
  const { changeTitle } = usePage()
  const { transactionId } = useParams()
  const { find, isRS } = useMoneyspace()
  const dispatch = useDispatch()
  const transaction = useSelector(TransactionSelectors.transaction)
  const moneyspace = find(transaction?.moneyspace.id)
  const { phaseName } = useConsts()

  useEffect(() => {
    if (transaction) {
      changeTitle(transaction?.name, phaseName(transaction?.currentPhase))
    }
  }, [changeTitle, phaseName, transaction])

  useEffect(() => {
    if (transactionId) {
      dispatch(TransactionActions.loadTransaction({ transactionId }))
    }
  }, [dispatch, transactionId])
  useEffect(() => {
    if (transaction) {
      dispatch(MoneyspacesActions.fetchMembers({ moneyspaceId: transaction.moneyspace.id }))
    }
  }, [dispatch, transaction])

  return (
    <FlexColumn>
      {transaction && moneyspace && (
        <>
          {isRS(moneyspace) ? (
            <TransactionRSForm moneyspace={moneyspace} transaction={transaction} />
          ) : (
            <TransactionForm moneyspace={moneyspace} transaction={transaction} />
          )}
        </>
      )}
    </FlexColumn>
  )
}
