import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import AttachmentsForm from '../../components/organisms/document/AttachmentsForm'
import { useDeviceType } from '../../hooks/useDeviceType'
import usePage from '../../hooks/usePage'
import Label from '../../components/atoms/display/Label'
import TransactionView from '../../components/organisms/transaction/TransactionView'
import TransactionDetailTable from '../../components/organisms/transaction/TransactionDetailTable'
import TransactionTimeline from '../../components/molecules/display/TransactionTimeline'
import { TransactionActions } from '../../store/transactions'
import { TransactionSelectors } from '../../store/transactions/selectors'
import useMoneyspace from '../../hooks/useMoneyspace'
import TransactionButtons from '../../components/organisms/transaction/TransactionButtons'
import TransactionStatusLabel from '../../components/molecules/display/TransactionStatusLabel'
import { SessionSelectors } from '../../store/session/selectors'
import usePermission from '../../hooks/usePermission'
import TransactionApprovalFlowStateView from '../../components/organisms/transaction/TransactionApprovalFlowStateView'
import FactoringDialog from '../../components/molecules/feedback/FactoringDialog'
import useFactoring from '../../hooks/useFactoring'
import Alert from '../../components/atoms/feedback/Alert'
import Button from '../../components/atoms/inputs/Button'
import useTransactionDiff from '../../hooks/useTransactionDiff'
import {
  useTransactionCancelDialog,
  useTransactionDeleteDialog,
  useTransactionPhaseDiffDialog,
} from '../../hooks/useDialog'
import { MoneyspacesActions } from '../../store/moneyspaces'
import {
  Transaction,
  TransactionPhase,
  TransactionPhaseDelivered,
  TransactionPhaseOrder,
  TransactionPhaseOrderConfirm,
  TransactionStatusDraft,
  TransactionStatusReview,
  TransactionStatusReviewed,
} from '../../types/transaction'
import useRouter from '../../hooks/useRouter'
import TextField from '../../components/atoms/inputs/TextField'
import FormItem from '../../components/molecules/inputs/FormItem'
import TransactionStepper from '../../components/molecules/navigation/TransactionStepper'
import TransactionPdfButtons from '../../components/organisms/transaction/TransactionPdfButtons'
import TransactionInstallmentDetailTable from '../../components/organisms/transaction/TransactionInstallmentDetailTable'
import TransactionInstallmentView from '../../components/organisms/transaction/TransactionInstallmentView'
import DecorationBox from '../../components/atoms/display/DecorationBox'
import useConsts from '../../hooks/useConsts'
import Table from '../../components/atoms/display/Table'
import TableRow from '../../components/atoms/display/TableRow'
import TableCell from '../../components/atoms/display/TableCell'
import useTransactionAmounts from '../../hooks/useTransactionAmounts'
import usePath from '../../hooks/usePath'
import Link from '../../components/atoms/navigation/Link'
import { BackIcon } from '../../components/atoms/display/Icons'
import { Company } from '../../types/Company'
import TransactionRSView from '../../components/organisms/transaction/TransactionRSView'

function covenantText(transaction?: Transaction, contractee?: Company) {
  if (!transaction || !contractee) {
    return null
  }
  if (transaction.currentPhase !== TransactionPhaseOrder) {
    return null
  }
  if ([TransactionStatusDraft, TransactionStatusReview].includes(transaction.status)) {
    return contractee.covenant
  }
  return transaction.covenant
}

export default function TransactionPage() {
  const { changeTitle } = usePage()
  const { transaction: transactionPage, moneyspace: moneyspacePage } = useRouter()
  const path = usePath()
  const { pc } = useDeviceType()
  const { transactionId } = useParams()
  const dispatch = useDispatch()
  const transaction = useSelector(TransactionSelectors.transaction)
  const installmentTransactions = useSelector(TransactionSelectors.installmentTransactions)
  const billing = transaction?.billing
  const selectedTransaction = useSelector(TransactionSelectors.selectedTransaction)
  const activities = useSelector(TransactionSelectors.activities)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const selectedPhase = Number(query.get('phase') ?? transaction?.currentPhase)
  const user = useSelector(SessionSelectors.user)
  const approvalFlowState = useSelector(TransactionSelectors.approvalFlowState)
  const previousPhase = useSelector(TransactionSelectors.previousPhase)
  const { find, isContractee, isRS } = useMoneyspace()
  const moneyspace = find(transaction?.moneyspace.id)
  const { transactionPermissions } = usePermission()
  const { factoringUrl, canFactoringTransaction } = useFactoring(transaction?.moneyspace.id)
  const [factoringDialogOpen, setFactoringDialogOpen] = useState(canFactoringTransaction(transaction))
  const transactionDiffDialog = useTransactionPhaseDiffDialog()
  const isCurrentPhase = selectedPhase === transaction?.currentPhase
  const [displayTransaction, setDisplayTransaction] = useState<Transaction>()
  const compareResult = useTransactionDiff(displayTransaction, previousPhase)
  const transactionDeleteDialog = useTransactionDeleteDialog()
  const transactionCancelDialog = useTransactionCancelDialog()
  const { phaseName } = useConsts()
  const { calcSimpleAmount } = useTransactionAmounts()
  const displayApprovalFlowState = isRS(moneyspace)
    ? displayTransaction?.currentPhase !== TransactionPhaseOrderConfirm
    : true

  useEffect(() => {
    changeTitle(moneyspace?.name ?? '')
  }, [changeTitle, moneyspace, transaction])
  useEffect(() => {
    if (transactionId) {
      dispatch(TransactionActions.loadTransaction({ transactionId }))
      if (!isCurrentPhase && selectedPhase) {
        dispatch(
          TransactionActions.loadSelectedTransaction({ transactionId, phase: selectedPhase as TransactionPhase })
        )
      } else {
        dispatch(TransactionActions.resetSelectedTransaction())
      }
      dispatch(TransactionActions.loadTransactionActivity({ transactionId }))
      dispatch(TransactionActions.loadApprovalFlowState({ transactionId }))
    }
  }, [dispatch, isCurrentPhase, selectedPhase, transactionId])
  useEffect(() => {
    if (transaction) {
      dispatch(MoneyspacesActions.fetchMembers({ moneyspaceId: transaction.moneyspace.id }))
    }
  }, [dispatch, transaction])
  useEffect(() => {
    if (transaction) {
      const selectedPhaseIndex = transaction?.phases.indexOf(selectedPhase as TransactionPhase)
      dispatch(TransactionActions.clearTransactionPreviousPhase())
      if (selectedPhaseIndex > 0) {
        dispatch(
          TransactionActions.fetchTransactionPreviousPhase({
            transactionId: transaction.id,
            phase: transaction.phases[selectedPhaseIndex - 1],
          })
        )
      }
    }
  }, [dispatch, selectedPhase, transaction])

  useEffect(() => {
    setDisplayTransaction(selectedTransaction ?? transaction)
  }, [selectedTransaction, transaction])

  const handleSelectPhase = useCallback(
    (phase: TransactionPhase) => {
      if (transaction && phase <= transaction.currentPhase) {
        transactionPage(transaction.id, phase)
      }
    },
    [transaction, transactionPage]
  )

  const handleOpenDiffDialog = useCallback(() => {
    if (moneyspace && displayTransaction && previousPhase) {
      transactionDiffDialog.open(
        moneyspace,
        displayTransaction,
        previousPhase,
        compareResult.changeLogs,
        isCurrentPhase,
        approvalFlowState
      )
    }
  }, [
    moneyspace,
    displayTransaction,
    previousPhase,
    transactionDiffDialog,
    compareResult.changeLogs,
    isCurrentPhase,
    approvalFlowState,
  ])

  if (transaction === undefined || moneyspace === undefined || displayTransaction === undefined) {
    return <></>
  }

  return (
    <FlexColumn space={4}>
      <FlexColumn>
        <Link href={path.moneyspace(moneyspace.id)}>
          <FlexRow>
            <BackIcon size={16} />
            <Label text="未処理・仕掛中に戻る" variant="caption" />
          </FlexRow>
        </Link>
        <FlexColumn space={0}>
          <Label text="取引名" variant="caption" />
          <Label text={transaction.name} variant="subtitle" />
        </FlexColumn>
      </FlexColumn>
      <FlexColumn width={pc ? 640 : 320}>
        <FlexRow align="center">
          <TransactionStepper
            transaction={transaction}
            selectedPhase={selectedPhase}
            onSelectPhase={handleSelectPhase}
          />
        </FlexRow>
      </FlexColumn>
      {transactionPermissions.view(isContractee(moneyspace, user), displayTransaction, moneyspace) ? (
        <FlexColumn width={1024}>
          <FlexRow justify="flex-end">
            {transaction.phases[0] === transaction.currentPhase &&
            transaction.status === TransactionStatusDraft &&
            transactionPermissions.delete(user) ? (
              <Button color="caution" onClick={() => transactionDeleteDialog.open(transaction)}>
                取引を削除する
              </Button>
            ) : (
              <>
                {transactionPermissions.cancel(isContractee(moneyspace, user), transaction, moneyspace, user) && (
                  <Button color="caution" onClick={() => transactionCancelDialog.open(transaction)}>
                    取引を中止する
                  </Button>
                )}
              </>
            )}
          </FlexRow>
          <DecorationBox type="Border">
            <FlexColumn pa={2}>
              <FlexRow>
                <FlexColumn>
                  <FlexRow align="center">
                    <Label text="フェーズ" variant="subtitle" />
                    <Label text={phaseName(displayTransaction.currentPhase)} />
                  </FlexRow>
                  <FlexRow align="center">
                    <Label text="ステータス" variant="subtitle" />
                    <TransactionStatusLabel transaction={displayTransaction} />
                  </FlexRow>
                </FlexColumn>
                <TransactionPdfButtons transactionId={transaction.id} phase={displayTransaction.currentPhase} />
              </FlexRow>
              {transactionPermissions.viewApprovalFlowState(
                isContractee(moneyspace, user),
                displayTransaction,
                moneyspace
              ) &&
                displayApprovalFlowState && (
                  <TransactionApprovalFlowStateView
                    transaction={displayTransaction}
                    moneyspace={moneyspace}
                    approvalFlowState={approvalFlowState}
                  />
                )}
              <TransactionButtons transaction={displayTransaction} approvalFlowState={approvalFlowState} />
              {billing && (
                <Button
                  height="small"
                  onClick={() =>
                    moneyspacePage(moneyspace.id, billing.isPaid ? 'completed' : 'pending', undefined, billing.id)
                  }
                >
                  請求書を確認
                </Button>
              )}
              {!isRS &&
                [TransactionStatusReview, TransactionStatusReviewed].includes(displayTransaction.status) &&
                compareResult.updated && (
                  <FlexRow align="center" justify="flex-end">
                    <Label text="このフェーズは前のフェーズに比べて、明細に差異があります！" color="error" />
                    <Button variant="text" height="small" onClick={handleOpenDiffDialog}>
                      変更点をレビュー
                    </Button>
                  </FlexRow>
                )}
              {transaction.installment ? (
                <FlexColumn>
                  <TransactionInstallmentDetailTable transaction={transaction} />
                  {installmentTransactions && <TransactionInstallmentView transaction={transaction} />}
                </FlexColumn>
              ) : (
                <FlexColumn align="flex-end">
                  <TransactionDetailTable details={displayTransaction.details} />
                  <FlexRow width={470}>
                    <Table>
                      <TableRow>
                        <TableCell color="header" size="sm3">
                          <Label text="合計金額" />
                        </TableCell>
                        <TableCell size="sm" align="right">
                          <Label text={calcSimpleAmount(transaction.details)} format="amountWithUnit" />
                        </TableCell>
                      </TableRow>
                      {isRS(moneyspace) && (
                        <>
                          <TableRow>
                            <TableCell cols={2}>
                              <FlexColumn space={0}>
                                <Label text="消費税及び消費税相当額は記載されておりません。" variant="caption" />
                                <Label text="支払明細書でご確認ください。" variant="caption" />
                              </FlexColumn>
                            </TableCell>
                          </TableRow>
                          {[TransactionPhaseDelivered].includes(displayTransaction.currentPhase) && (
                            <TableRow>
                              <TableCell color="header" size="sm3">
                                <Label text="過不足金額" />
                              </TableCell>
                              <TableCell size="sm" align="right">
                                <Label text={transaction.optional?.differencePrice ?? ''} format="amountWithUnit" />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </Table>
                  </FlexRow>
                </FlexColumn>
              )}
              <FormItem label="備考">
                <TextField value={displayTransaction.note ?? ''} multiline readonly rows={10} />
              </FormItem>
              <Label text="取引情報" variant="subtitle" />
              {isRS(moneyspace) ? (
                <TransactionRSView
                  moneyspace={moneyspace}
                  transaction={displayTransaction}
                  isContractee={isContractee(moneyspace, user)}
                />
              ) : (
                <TransactionView
                  moneyspace={moneyspace}
                  transaction={displayTransaction}
                  isContractee={isContractee(moneyspace, user)}
                />
              )}
            </FlexColumn>
          </DecorationBox>
          <AttachmentsForm
            transactionId={transaction.id}
            disabled={[4, 9].includes(transaction.status)}
            breakdown={
              transaction.optional?.pdf
                ? { id: 'breakdown', url: transaction.optional.pdf.url, name: '注文内訳書.pdf' }
                : undefined
            }
          />
          {covenantText(displayTransaction, moneyspace.contractee) && (
            <FlexColumn space={0}>
              <Label text="誓約条項" />
              <TextField
                value={covenantText(displayTransaction, moneyspace.contractee) ?? ''}
                multiline
                rows={10}
                readonly
              />
              <Label text="monectで次の取引に進めると、上記の誓約条項に同意したものとみなします。" />
            </FlexColumn>
          )}
          <TransactionTimeline activities={activities} />
        </FlexColumn>
      ) : (
        <FlexColumn>
          <Alert severity="error" label="取引先が編集中のため閲覧できません" />
          {isRS(moneyspace) && (
            <AttachmentsForm
              transactionId={transaction.id}
              breakdown={
                transaction.optional?.pdf
                  ? { id: 'breakdown', url: transaction.optional.pdf.url, name: '注文内訳書.pdf' }
                  : undefined
              }
              disabled={[4, 9].includes(transaction.status)}
            />
          )}
        </FlexColumn>
      )}
      <FactoringDialog url={factoringUrl} open={factoringDialogOpen} onClose={() => setFactoringDialogOpen(false)} />
    </FlexColumn>
  )
}
