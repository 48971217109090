import { Request } from './Request'
import { DashboardData, MaintenanceInfo } from '../types/System'

export default class SystemRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadDashboard() {
    const response = await this.request.get<DashboardData>('/dashboard')
    return response.data
  }

  async loadMaintenanceInfo() {
    const response = await this.request.get<MaintenanceInfo>('/maintenance-info')
    return response.data
  }

  async loadConsts() {
    const response = await this.request.get<{
      jobOfferConditionType: { [key: string]: string }
      jobWorkType: { [key: string]: string }
    }>('/consts')
    return response.data
  }
}
