import { useSelector } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import Label from '../../atoms/display/Label'

type DeliveredTransactionImportFailuresDialogProps = {
  open: boolean
  onClose: () => void
}

export default function DeliveredTransactionImportFailuresDialog({
  open,
  onClose,
}: DeliveredTransactionImportFailuresDialogProps) {
  const failures = useSelector(TransactionSelectors.importFailureDeliveredTransactions)
  return (
    <Dialog open={open} title="完納取引の取り込みエラー" width="xl" onClose={onClose}>
      <Table
        header={
          <TableRow>
            <TableCell header size="sm2">
              注文No.
            </TableCell>
            <TableCell header size="sm">
              MS名
            </TableCell>
            <TableCell header size="xs">
              金額
            </TableCell>
            <TableCell header size="xs">
              エラーコード
            </TableCell>
            <TableCell header />
          </TableRow>
        }
      >
        <>
          {failures.map((failure) => (
            <TableRow key={failure.id}>
              <TableCell size="sm2" align="center">
                {failure.clientAppId}
              </TableCell>
              <TableCell size="sm">{failure.msName}</TableCell>
              <TableCell size="xs" align="right">
                <Label text={failure.amount} format="amountWithUnit" />
              </TableCell>
              <TableCell size="xs" align="center">
                {failure.error.code}
              </TableCell>
              <TableCell>{failure.error.message}</TableCell>
            </TableRow>
          ))}
        </>
      </Table>
    </Dialog>
  )
}
