import { RelatedUser } from './User'
import { TransactionSearchParams } from './search'

export type TaxBucket =
  | 1 // 10%
  | 2 // 軽減8%
  | 3 // 非課税

export type BankAccountType =
  | 1 // 普通
  | 2 // 当座

export type TransactionPhase =
  | 1 // 見積り依頼
  | 2 // 見積もり
  | 3 // 発注
  | 4 // 発注請
  | 5 // 完納
  | 6 // 支払明細
  | 7 // 請求
export const TransactionPhaseEFQ = 1 as TransactionPhase
export const TransactionPhaseQuotation = 2 as TransactionPhase
export const TransactionPhaseOrder = 3 as TransactionPhase
export const TransactionPhaseOrderConfirm = 4 as TransactionPhase
export const TransactionPhaseDelivered = 5 as TransactionPhase
export const TransactionPhaseBillingDetail = 6 as TransactionPhase
export const TransactionPhaseBilling = 7 as TransactionPhase

export type TransactionStatus =
  | 1 // 下書き
  | 2 // 社内確認
  | 3 // 取引先確認
  | 4 // 完了
  | 9 // 中止
export const TransactionStatusDraft = 1 as TransactionStatus
export const TransactionStatusReview = 2 as TransactionStatus
export const TransactionStatusReviewed = 3 as TransactionStatus
export const TransactionStatusClosed = 4 as TransactionStatus
export const TransactionStatusCanceled = 9 as TransactionStatus

export type TransactionDetail = {
  id: string
  defaultProp?: string | null
  customProp1?: string | null
  customProp2?: string | null
  unitPrice: number
  quantity: number
  unit: string
  taxBucket: 1 | 2 | 3
  amount: number
}

export type TransactionInstallmentDetail = {
  id: string
  amount: number
}

export type Transaction = {
  id: string
  moneyspace: {
    id: string
    name: string
    group: string
    contractee: {
      id: string
      name: string
    }
    contractor: {
      id: string
      name: string
    }
  }
  details: TransactionDetail[]
  contractee: {
    name: string
    representativeName: string
    representativeRole: string
    zipCode: string
    address: string
  } | null
  contractor: {
    name: string
    representativeName: string
    representativeRole: string
    zipCode: string
    address: string
    bankName: string
    bankCode: string
    branchName: string
    branchCode: string
    accountType: BankAccountType
    accountNumber: string
    accountOwner: string
  } | null
  name: string
  siteAddress: string | null
  createdAt: string
  updatedAt: string
  fixFollowingPhase: boolean
  phases: TransactionPhase[]
  currentPhase: TransactionPhase
  status: TransactionStatus
  isTaxIn: boolean
  publishedAt: string | null
  note: string
  paymentDate: string | null
  closingDate: string | null
  bearingFee: boolean
  deliveryDateFrom: string | null
  deliveryDateTo: string | null
  pic: RelatedUser | null
  picSub: RelatedUser | null
  billing: {
    id: string
    isPaid: boolean
  } | null
  ownerUid: string
  times?: number | null
  installment?: {
    id: string
    amount: number
    subTotal: number
    details: {
      original: TransactionDetail[]
      times: {
        [key: number]: {
          id: string
          balance: number
          paid: number
        }[]
      }
    }
  }
  invoiceLessActionType?: number | null
  customProp1?: string | null
  customProp2?: string | null
  customProp3?: string | null
  customProp4?: string | null
  optional?: {
    clientAppId: string
    differencePrice: number
    constructionNo: string
    rsUserName?: string
    branchName?: string
    pdf: {
      objectKey: string
      url: string
    } | null
  }
  covenant?: string | null
  isFavorite?: boolean
}

export type TransactionActivityAction =
  | 1 // フェーズ開始
  | 2 // フェーズ更新
  | 3 // 確認申請
  | 4 // 取引先提出
  | 5 // 承認
  | 6 // 却下
  | 7 // フェーズ完了
  | 8 // 取引中止

export type TransactionActivity = {
  id: string
  phase: TransactionPhase
  actionType: TransactionActivityAction
  actionedAt: string
  actionedBy?: RelatedUser
  note?: string
}

export type TransactionSearchResultItem = {
  id: string
  name: string
  moneyspace: {
    id: string
    name: string
    contractor?: {
      id: string
      name: string
    }
    contractee?: {
      id: string
      name: string
    }
    externalId?: string | null
  }
  isTaxIn: boolean
  publishedAt: string
  paymentDate: string | null
  closingDate: string | null
  currentPhase: TransactionPhase
  phases: TransactionPhase[]
  status: TransactionStatus
  subtotal: number
  details: TransactionDetail[]
  updatedAt: string
  times: number | null
  deliveryDateFrom?: string
  deliveryDateTo?: string
  pic: RelatedUser | null
  picSub: RelatedUser | null
  optional?: {
    clientAppId: string
    differencePrice: number
    constructionNo: string
    rsUserName?: string
    branchName?: string
  }
  isFavorite: boolean
}

export type TransactionDetailSearchResultItem = {
  id: string
  description: string
  amount: number
  subtotal: number
  transaction: Transaction
}

export type BillingSearchResultItem = {
  id: string
  moneyspace: {
    id: string
    name: string
  }
  clientName: string
  closingDate: string | null
  fixDate: string | null
  paymentDate: string | null
  isPaid: boolean
  transactions: TransactionSearchResultItem[]
  type: TransactionPhase
  subtotal: number
  subtotal8: number
  subtotal10: number
  totalMembershipFee: number
  totalTax: number
  totalTax8: number
  totalTax10: number
  totalAmount: number
  createdAt: string
  invoiceLessActionType?: number | null
}

export type TransactionSearchConditionResource = {
  id: string
  name: string
  query: string
}

export type TransactionSearchConditionGetResponse = {
  searchQueries: TransactionSearchConditionResource[]
}

export type TransactionSearchConditionCreateResponse = {
  id: string
  name: string
  query: string
}

export type TransactionSearchCondition = {
  id: string
  name: string
  params: TransactionSearchParams
}

export type FactoringTransaction = {
  factoringUrl: string
} & TransactionSearchResultItem

export type PhaseChangeLog = {
  status: 'same' | 'add' | 'delete' | 'change'
  previousNo?: number
  currentNo?: number
  previousDetail?: TransactionDetail
  currentDetail?: TransactionDetail
}

export type TransactionPhaseDiffsResponse = {
  previousPhase: {
    id: string
    detailIds: string[]
  }
  currentPhase: {
    id: string
    detailIds: string[]
  }
}

export type TransactionInstallmentParams = {
  publishedAt: string
  closingDate: string
  paymentDate: string
  deliveryDateFrom: string | null
  deliveryDateTo: string | null
  pic: string | null
  picSub: string | null
  details: TransactionInstallmentDetail[]
}

export type TransactionDeliveredImportResponse = {
  failures: TransactionDeliveredImportFailure[]
}

export type TransactionDeliveredImportFailure = {
  id: string
  name: string
  amount: number
  clientAppId: string
  msName: string
  error: TransactionDeliveredImportError
}

export type TransactionDeliveredImportError = {
  code: string
  message: string
}
