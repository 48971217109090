import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useJobWorkLocationSettingDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import { JobSelectors } from '../../../store/job/selectors'
import Checkbox from '../../atoms/inputs/Checkbox'
import { JobActions } from '../../../store/job'

export default function JobWorkLocationSettingDialog() {
  const { state, close } = useJobWorkLocationSettingDialog()
  const areas = useSelector(JobSelectors.areas)
  const workLocations = useSelector(JobSelectors.workLocations)
  const [selectedAreaIds, setSelectedAreaIds] = useState<string[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (workLocations) {
      setSelectedAreaIds(workLocations.map((workLocation) => workLocation.area.id))
    }
  }, [workLocations])

  const handleCheck = useCallback(
    (areaId: string, checked) => {
      if (checked) {
        setSelectedAreaIds([...selectedAreaIds, areaId])
      } else {
        setSelectedAreaIds(selectedAreaIds.filter((id) => id !== areaId))
      }
    },
    [selectedAreaIds]
  )

  const handleSave = useCallback(() => {
    dispatch(JobActions.updateWorkLocations({ areaIds: selectedAreaIds }))
  }, [dispatch, selectedAreaIds])

  return (
    <Dialog
      open={state !== undefined}
      title="勤務地設定"
      width="xs"
      actions={<Button onClick={handleSave}>保存</Button>}
      onClose={close}
    >
      <FlexColumn maxHeight="60vh">
        {areas.map((area) => (
          <Checkbox
            key={area.id}
            label={area.name}
            checked={selectedAreaIds.includes(area.id)}
            onChange={(checked) => handleCheck(area.id, checked)}
          />
        ))}
      </FlexColumn>
    </Dialog>
  )
}
