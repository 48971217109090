import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useJobOfferDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import { JobActions } from '../../../store/job'
import { JobSelectors } from '../../../store/job/selectors'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'

export default function JobOfferDeleteDialog() {
  const { state, close } = useJobOfferDeleteDialog()
  const dispatch = useDispatch()
  const offers = useSelector(JobSelectors.offers)
  const jobOffer = offers.find((offer) => offer.id === state?.jobOfferId)

  const handleDeleteOffer = useCallback(() => {
    if (state) {
      dispatch(JobActions.deleteOffer({ offerId: state.jobOfferId }))
    }
  }, [dispatch, state])

  if (!jobOffer) {
    return null
  }
  return (
    <Dialog
      open={state !== undefined}
      title="求人削除"
      width="xs"
      actions={
        <Button color="caution" onClick={handleDeleteOffer}>
          削除
        </Button>
      }
      onClose={close}
    >
      <FlexColumn maxHeight="60vh">
        <Label text={jobOffer.name} variant="subtitle" />
        <Label text="この求人を削除しますか？" />
      </FlexColumn>
    </Dialog>
  )
}
