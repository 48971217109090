import {
  Add,
  AddBoxOutlined,
  Approval,
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  AttachFile,
  Business,
  Calculate,
  CalendarMonth,
  Circle,
  CircleOutlined,
  Close,
  CloudDownload,
  CloudUpload,
  CurrencyYen,
  Dashboard,
  Delete,
  Description,
  DescriptionOutlined,
  DriveFileMove,
  Edit,
  Email,
  FileCopyOutlined,
  Folder,
  FolderOutlined,
  Group,
  GroupOutlined,
  Groups,
  HelpOutlined,
  Inbox,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalOffer,
  LockOpenOutlined,
  LockOutlined,
  Logout,
  Mail,
  Menu,
  North,
  PendingActions,
  People,
  Person,
  PersonAdd,
  PersonRemove,
  PictureAsPdf,
  Print,
  PushPin,
  PushPinOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Receipt,
  Refresh,
  RemoveCircle,
  RotateLeft,
  RotateRight,
  SaveAlt,
  Search,
  Send,
  Settings,
  South,
  Star,
  StarBorder,
  Store,
  Task,
  WarningAmberOutlined,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import { ElementType } from 'react'
import { SvgIcon } from '@mui/material'

export type IconSize = 8 | 16 | 24 | 40 | 48 | 64 | 128 | 256
type IconColor = 'primary' | 'secondary' | 'white'

type IconProps = {
  size?: IconSize
  color?: IconColor
}

type BaseIconProps = {
  icon: ElementType
} & IconProps

export function Icon({ icon, size, color }: BaseIconProps) {
  return <SvgIcon component={icon} sx={{ fontSize: size }} color={color} />
}

export function AccountIcon(props: IconProps) {
  return <Icon {...props} icon={Person} />
}

export function AccountsIcon(props: IconProps) {
  return <Icon {...props} icon={People} />
}

export function AggregationIcon(props: IconProps) {
  return <Icon {...props} icon={Calculate} />
}

export function ApprovalFlowIcon(props: IconProps) {
  return <Icon {...props} icon={Approval} />
}

export function ArrowBackIcon(props: IconProps) {
  return <Icon {...props} icon={ArrowBack} />
}

export function ArrowForwardIcon(props: IconProps) {
  return <Icon {...props} icon={ArrowForward} />
}

export function AttachmentIcon(props: IconProps) {
  return <Icon {...props} icon={AttachFile} />
}

export function BackIcon(props: IconProps) {
  return <Icon {...props} icon={ArrowBackIos} />
}

export function BillingIcon(props: IconProps) {
  return <Icon {...props} icon={Description} />
}

export function CalendarIcon(props: IconProps) {
  return <Icon {...props} icon={CalendarMonth} />
}

export function ChatIcon(props: IconProps) {
  return <Icon {...props} icon={Mail} />
}

export function CompanyIcon(props: IconProps) {
  return <Icon {...props} icon={Business} />
}

export function ContractIcon(props: IconProps) {
  return <Icon {...props} icon={Receipt} />
}

export function CircleIcon(props: IconProps) {
  return <Icon {...props} icon={Circle} />
}

export function ClientIcon(props: IconProps) {
  return <Icon {...props} icon={Store} />
}

export function CloseIcon(props: IconProps) {
  return <Icon {...props} icon={Close} />
}

export function CopyIcon(props: IconProps) {
  return <Icon {...props} icon={FileCopyOutlined} />
}

export function DashboardIcon(props: IconProps) {
  return <Icon {...props} icon={Dashboard} />
}

export function DeleteIcon(props: IconProps) {
  return <Icon {...props} icon={Delete} />
}

export function DeliveredIcon(props: IconProps) {
  return <Icon {...props} icon={Task} />
}

export function DocumentIcon(props: IconProps) {
  return <Icon {...props} icon={DescriptionOutlined} />
}

export function DownIcon(props: IconProps) {
  return <Icon {...props} icon={KeyboardArrowDown} />
}

export function DownloadIcon(props: IconProps) {
  return <Icon {...props} icon={CloudDownload} />
}

export function EditIcon(props: IconProps) {
  return <Icon {...props} icon={Edit} />
}

export function ErrorIcon(props: IconProps) {
  return <Icon {...props} icon={WarningAmberOutlined} />
}

export function FavoriteIcon(props: IconProps) {
  return <Icon {...props} icon={Star} />
}

export function FavoriteOutlinedIcon(props: IconProps) {
  return <Icon {...props} icon={StarBorder} />
}

export function FolderIcon(props: IconProps) {
  return <Icon {...props} icon={FolderOutlined} />
}

export function HelpIcon(props: IconProps) {
  return <Icon {...props} icon={HelpOutlined} />
}

export function InfoIcon(props: IconProps) {
  return <Icon {...props} icon={InfoOutlined} />
}

export function ImportIcon(props: IconProps) {
  return <Icon {...props} icon={SaveAlt} />
}

export function JobIcon(props: IconProps) {
  return <Icon {...props} icon={Groups} />
}

export function LockedIcon(props: IconProps) {
  return <Icon {...props} icon={LockOutlined} />
}

export function LogoutIcon(props: IconProps) {
  return <Icon {...props} icon={Logout} />
}

export function MenuIcon(props: IconProps) {
  return <Icon {...props} icon={Menu} />
}

export function MessageIcon(props: IconProps) {
  return <Icon {...props} icon={Email} />
}

export function MoneyspaceAddIcon(props: IconProps) {
  return <Icon {...props} icon={Add} />
}

export function MoneyspaceGroupIcon(props: IconProps) {
  return <Icon {...props} icon={Folder} />
}

export function MoneyspaceIcon(props: IconProps) {
  return <Icon {...props} icon={Person} />
}

export function MoveIcon(props: IconProps) {
  return <Icon {...props} icon={DriveFileMove} />
}

export function NGIcon(props: IconProps) {
  return <Icon {...props} icon={Close} />
}

export function OKIcon(props: IconProps) {
  return <Icon {...props} icon={CircleOutlined} />
}

export function PaymentIcon(props: IconProps) {
  return <Icon {...props} icon={CurrencyYen} />
}

export function PendingApprovalDocumentIcon(props: IconProps) {
  return <Icon {...props} icon={PendingActions} />
}

export function PdfIcon(props: IconProps) {
  return <Icon {...props} icon={PictureAsPdf} />
}

export function PinIcon(props: IconProps) {
  return <Icon {...props} icon={PushPinOutlined} />
}

export function PinnedIcon(props: IconProps) {
  return <Icon {...props} icon={PushPin} />
}

export function PlusIcon(props: IconProps) {
  return <Icon {...props} icon={AddBoxOutlined} />
}

export function PrintIcon(props: IconProps) {
  return <Icon {...props} icon={Print} />
}

export function PrivateIcon(props: IconProps) {
  return <Icon {...props} icon={LockOutlined} />
}

export function PublicIcon(props: IconProps) {
  return <Icon {...props} icon={GroupOutlined} />
}

export function RadioCheckedIcon(props: IconProps) {
  return <Icon {...props} icon={RadioButtonChecked} />
}

export function RadioUncheckedIcon(props: IconProps) {
  return <Icon {...props} icon={RadioButtonUnchecked} />
}

export function ReceivedDocumentIcon(props: IconProps) {
  return <Icon {...props} icon={Inbox} />
}

export function RemoveIcon(props: IconProps) {
  return <Icon {...props} icon={RemoveCircle} />
}

export function RotateLeftIcon(props: IconProps) {
  return <Icon {...props} icon={RotateLeft} />
}

export function RotateRightIcon(props: IconProps) {
  return <Icon {...props} icon={RotateRight} />
}

export function SearchIcon(props: IconProps) {
  return <Icon {...props} icon={Search} />
}

export function SendIcon(props: IconProps) {
  return <Icon {...props} icon={Send} />
}

export function SettingsIcon(props: IconProps) {
  return <Icon {...props} icon={Settings} />
}

export function SharedMoneyspaceIcon(props: IconProps) {
  return <Icon {...props} icon={Group} />
}

export function SortAscIcon(props: IconProps) {
  return <Icon {...props} icon={North} />
}

export function SortDescIcon(props: IconProps) {
  return <Icon {...props} icon={South} />
}

export function TagIcon(props: IconProps) {
  return <Icon {...props} icon={LocalOffer} />
}

export function UpIcon(props: IconProps) {
  return <Icon {...props} icon={KeyboardArrowUp} />
}

export function UpdateIcon(props: IconProps) {
  return <Icon {...props} icon={Refresh} />
}

export function UploadIcon(props: IconProps) {
  return <Icon {...props} icon={CloudUpload} />
}

export function UnLockedIcon(props: IconProps) {
  return <Icon {...props} icon={LockOpenOutlined} />
}

export function UserAddIcon(props: IconProps) {
  return <Icon {...props} icon={PersonAdd} />
}

export function UserRemoveIcon(props: IconProps) {
  return <Icon {...props} icon={PersonRemove} />
}

export function ZoomInIcon(props: IconProps) {
  return <Icon {...props} icon={ZoomIn} />
}

export function ZoomOutIcon(props: IconProps) {
  return <Icon {...props} icon={ZoomOut} />
}
