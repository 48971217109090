import { Request } from './Request'
import { Job, JobArea, JobImage, JobOffer, JobOfferAttributes, WorkLocation } from '../types/job'

export default class JobRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadJob(jobId: string) {
    const response = await this.request.get<Job>(`/jobs/${jobId}`)
    return response.data
  }

  async loadWorkLocations(jobId: string) {
    const response = await this.request.get<WorkLocation[]>(`/jobs/${jobId}/work-locations`)
    return response.data
  }

  async loadAreas() {
    const response = await this.request.get<JobArea[]>(`/job-areas`)
    return response.data
  }

  async createJob(params: {
    establishedYear: number | null
    numberOfEmployees: number
    workType1: number
    workType1Detail: string
    workType2: number
    workType2Detail: string
    workType3: number
    workType3Detail: string
    siteUrl: string | null
  }) {
    const response = await this.request.post<Job>(`/jobs`, {
      establishedYear: params.establishedYear,
      numberOfEmployees: params.numberOfEmployees,
      workType_1: params.workType1,
      workType1Detail: params.workType1Detail,
      workType_2: params.workType2,
      workType2Detail: params.workType2Detail,
      workType_3: params.workType3,
      workType3Detail: params.workType3Detail,
      siteUrl: params.siteUrl,
    })
    return response.data
  }

  async updateJob(
    jobId: string,
    params: {
      establishedYear: number | null
      numberOfEmployees: number
      workType1: number
      workType1Detail: string
      workType2: number
      workType2Detail: string
      workType3: number
      workType3Detail: string
      siteUrl: string | null
    }
  ) {
    const response = await this.request.put<Job>(`/jobs/${jobId}`, {
      establishedYear: params.establishedYear,
      numberOfEmployees: params.numberOfEmployees,
      workType_1: params.workType1,
      workType1Detail: params.workType1Detail,
      workType_2: params.workType2,
      workType2Detail: params.workType2Detail,
      workType_3: params.workType3,
      workType3Detail: params.workType3Detail,
      siteUrl: params.siteUrl,
    })
    return response.data
  }

  async updateWorkLocations(jobId: string, areaIds: string[]) {
    const response = await this.request.put<WorkLocation[]>(`/jobs/${jobId}/work-locations`, {
      areaIds,
    })
    return response.data
  }

  async loadImages(jobId: string) {
    const response = await this.request.get<{ images: JobImage[] }>(`/jobs/${jobId}/images`)
    return response.data.images
  }

  async uploadImage(jobId: string, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await this.request.post<JobImage>(`/jobs/${jobId}/images`, formData)
    return response.data
  }

  async deleteImage(jobId: string, id: string) {
    await this.request.delete(`/jobs/${jobId}/images/${id}`)
  }

  async loadOffers(jobId: string) {
    const response = await this.request.get<JobOffer[]>(`/jobs/${jobId}/offers`)
    return response.data
  }

  async createOffer(jobId: string, offer: JobOfferAttributes) {
    const response = await this.request.post<JobOffer>(`/jobs/${jobId}/offers`, offer)
    return response.data
  }

  async updateOffer(jobId: string, jobOfferId: string, offer: JobOfferAttributes) {
    const response = await this.request.put<JobOffer>(`/jobs/${jobId}/offers/${jobOfferId}`, offer)
    return response.data
  }

  async deleteOffer(jobId: string, jobOfferId: string) {
    await this.request.delete(`/jobs/${jobId}/offers/${jobOfferId}`)
  }

  async updateOfferPublishedStatus(jobId: string, jobOfferId: string, publishedStatus: number) {
    await this.request.put(`/jobs/${jobId}/offers/${jobOfferId}/published-status`, {
      publishedStatus,
    })
  }
}
