import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import { CompanySelectors } from '../../store/company/selectors'
import CompanyView from '../../components/organisms/job/CompanyView'
import ImageView from '../../components/organisms/job/ImageView'
import Label from '../../components/atoms/display/Label'
import { JobActions } from '../../store/job'
import { JobSelectors } from '../../store/job/selectors'
import Button from '../../components/atoms/inputs/Button'
import { useJobImageSettingDialog } from '../../hooks/useDialog'
import OffersView from '../../components/organisms/job/OffersView'
import useRouter from '../../hooks/useRouter'

export default function JobPage() {
  const { changeTitle } = usePage()
  const company = useSelector(CompanySelectors.company)
  const job = useSelector(JobSelectors.job)
  const offers = useSelector(JobSelectors.offers)
  const dispatch = useDispatch()
  const imageSettingDialog = useJobImageSettingDialog()
  const { jobOfferNew } = useRouter()

  useEffect(() => {
    changeTitle('求人管理')
  }, [changeTitle])

  useEffect(() => {
    dispatch(JobActions.fetchJob())
    dispatch(JobActions.fetchImages())
    dispatch(JobActions.fetchOffers())
  }, [dispatch])

  if (!company) {
    return null
  }

  return (
    <FlexColumn space={4}>
      <CompanyView company={company} job={job} />
      <FlexColumn>
        <FlexRow align="center">
          <Label text="画像" variant="subtitle" />
          <Button size="sm" height="small" onClick={imageSettingDialog.open}>
            編集
          </Button>
        </FlexRow>
        <ImageView />
      </FlexColumn>
      <FlexColumn>
        <FlexRow align="center">
          <Label text="求人一覧" variant="subtitle" />
          <Button size="sm" height="small" onClick={jobOfferNew}>
            新規登録
          </Button>
        </FlexRow>
        <OffersView offers={offers} />
      </FlexColumn>
    </FlexColumn>
  )
}
