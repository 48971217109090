import { useSelector } from 'react-redux'
import { FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { JobSelectors } from '../../../store/job/selectors'

export default function AreaView() {
  const workLocations = useSelector(JobSelectors.workLocations)
  return (
    <FlexRow wrap>
      {workLocations.map((location) => (
        <Label key={location.area.id} text={location.area.name} />
      ))}
    </FlexRow>
  )
}
