import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const jobSelector = (state: StoreState) => state.job
export const JobSelectors = {
  job: createSelector([jobSelector], (state) => state.job),
  workLocations: createSelector([jobSelector], (state) => state.workLocations),
  areas: createSelector([jobSelector], (state) => state.areas),
  images: createSelector([jobSelector], (state) => state.images),
  offers: createSelector([jobSelector], (state) => state.offers),
}
