export type BankAccount = {
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  accountType: number
  accountNumber?: string
  accountOwner?: string
  payAfterHolidays: boolean
}

export type Address = {
  zipCode?: string
  address?: string
}

export type CompanyBasic = {
  name: string
  representativeName?: string
  representativeRole?: string
  uid?: string
  invoiceLicenseNumber?: string | null
  invoiceLessActionType?: number | null
  fiscalPeriod?: number
  phoneNumber?: string
  phoneNumberMobile?: string
  covenant?: string | null
  jobId?: string | null
} & Address

export type AggregateTransaction = {
  aggregateTransactions: boolean
  fixMonth: number | null
  fixDay: number | null
}

export type CompanyAttributes = CompanyBasic & BankAccount

export type CompanyMembershipFee = {
  name: string
  isTaxIn: boolean
  useRate: boolean
  chart: number[][]
  maximumFee: number | null
  useTax: boolean
  roundingType: number
} | null

export type TransactionPropConfig = {
  customProp1: {
    name: string
    required: boolean
  }
  customProp2: {
    name: string
    required: boolean
  }
  customProp3: {
    name: string
    required: boolean
  }
  customProp4: {
    name: string
    required: boolean
  }
}

export type DetailPropConfig = {
  requiredPic?: boolean
  requiredDeliveryDate?: boolean
  defaultProp: {
    name: string
    required: boolean
  }
  customProp1: {
    name: string
    required: boolean
  }
  customProp2: {
    name: string
    required: boolean
  }
}

export const CompanyTypeNormal = 'normal'
export const CompanyTypeRS = 'rs'

export type Company = {
  id: string
  uid: string
  type: 'normal' | 'rs'
  isRelatedMs: boolean
  isAccepted: boolean
  hasLagLessAccount?: boolean
  isInviter?: boolean
  membershipFee: CompanyMembershipFee
  detailPropConfig: DetailPropConfig
  transactionPropConfig: TransactionPropConfig
  taxRoundingType: number
} & CompanyAttributes &
  AggregateTransaction

export type Bank = {
  code: string
  name: string
}

export type BankBranch = {
  code: string
  name: string
}

export type CsvImportSetting = {
  bundlingKeyField: 'name' | 'custom_prop_1' | 'custom_prop_2' | 'custom_prop_3' | 'custom_prop_4'
  externalId: string
  name: string
  isTaxIn: string
  publishedAt: string
  note: string
  paymentDate: string
  closingDate: string
  deliveryDateFrom: string
  deliveryDateTo: string
  picId: string
  picSubId: string
  siteAddress: string
  customProp1: string
  customProp2: string
  customProp3: string
  customProp4: string
  detailDefaultProp: string
  detailCustomProp1: string
  detailCustomProp2: string
  detailUnitPrice: string
  detailQuantity: string
  detailUnit: string
  detailTaxBucketId: string
  skippingKeyField: string | null
  skippingTargetValues: { value: string }[]
}

export type CsvImportSettingGetResponse = {
  bundlingKeyField: 'name' | 'custom_prop_1' | 'custom_prop_2' | 'custom_prop_3' | 'custom_prop_4'
  externalId: string
  name: string
  isTaxIn: string
  publishedAt: string
  note: string
  paymentDate: string
  closingDate: string
  deliveryDateFrom: string
  deliveryDateTo: string
  picId: string
  picSubId: string
  siteAddress: string
  customProp1: string
  customProp2: string
  customProp3: string
  customProp4: string
  detailDefaultProp: string
  detailCustomProp1: string
  detailCustomProp2: string
  detailUnitPrice: string
  detailQuantity: string
  detailUnit: string
  detailTaxBucketId: string
  skippingKeyField: string | null
  skippingTargetValues: string[] | null
}
