import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { MembershipFee2 } from '../../../types/Moneyspace'
import { CompanySelectors } from '../../../store/company/selectors'

type MembershipFeeViewProps = {
  membershipFee: MembershipFee2
}

export default function MembershipFeeView({ membershipFee }: MembershipFeeViewProps) {
  const membershipFeeName = useSelector(CompanySelectors.membershipFeeName)
  return (
    <FlexColumn>
      {membershipFee.conditions.length === 0 ? (
        <Label text={`${membershipFeeName}なし`} />
      ) : (
        <>
          <FlexRow scroll={false}>
            <FlexRow width={336} scroll={false}>
              <Label text="請求金額" />
              {membershipFee.taxIn === 2 ? <Label text="(税込み）" /> : <Label text="(税抜）" />}
            </FlexRow>
            <FlexRow width={120} scroll={false}>
              <Label text={`${membershipFeeName}額`} />
            </FlexRow>
          </FlexRow>
          {membershipFee.conditions.map((item, index) => (
            <FlexRow scroll={false}>
              <FlexRow width={336} scroll={false}>
                <FlexRow width={160} justify="flex-end">
                  <Label
                    text={index === 0 ? 0 : membershipFee.conditions[index - 1].amount + 1}
                    format="amountNoStyle"
                  />
                  <Label text="円以上" />
                </FlexRow>
                {membershipFee.conditions.length !== index + 1 && (
                  <FlexRow width={160} justify="flex-end">
                    <Label text={item.amount} format="amountNoStyle" />
                    <Label text="円以下" />
                  </FlexRow>
                )}
              </FlexRow>
              <FlexRow scroll={false}>
                <Label text={item.value} format="amountNoStyle" />
                {membershipFee.type === 2 ? <Label text="%" /> : <Label text="円" />}
              </FlexRow>
            </FlexRow>
          ))}
        </>
      )}
      {membershipFee.maximumFee && (
        <FlexRow>
          <Label text={`${membershipFeeName}の上限金額`} />
          <Label text={membershipFee.maximumFee} format="amountWithUnit" />
        </FlexRow>
      )}
    </FlexColumn>
  )
}
