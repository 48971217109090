import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { DialogsSelectors } from '../store/dialogs/selectors'
import dialogsSlice from '../store/dialogs'
import { User } from '../types/User'
import { Company, CompanyAttributes } from '../types/Company'
import { Tag, Document, Contract, Attachment, DocumentItem, ChangeLog, DocumentAttributes } from '../types/Document'
import { Moneyspace, MoneyspaceFolder } from '../types/Moneyspace'
import { ApprovalFlow, ApprovalFlowState } from '../types/ApprovalFlow'
import { SortDirection } from '../types/System'
import {
  Transaction,
  PhaseChangeLog,
  TransactionDetail,
  TransactionPhase,
  TransactionInstallmentParams,
  TransactionSearchResultItem,
} from '../types/transaction'
import { BillingSearchParams } from '../types/search'
import { ReservedMessage } from '../types/Chat'

export function useUserCreateDialog() {
  const state = useSelector(DialogsSelectors.userCreate)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openUserCreateDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeUserCreateDialog())
    }, [dispatch]),
  }
}

export function useUserEditDialog() {
  const state = useSelector(DialogsSelectors.userEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (user: User) => {
        dispatch(dialogsSlice.actions.openUserEditDialog({ user }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeUserEditDialog())
    }, [dispatch]),
  }
}

export function useUserDeleteDialog() {
  const state = useSelector(DialogsSelectors.userDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (user: User) => {
        dispatch(dialogsSlice.actions.openUserDeleteDialog({ user }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeUserDeleteDialog())
    }, [dispatch]),
  }
}

export function useClientCreateDialog() {
  const state = useSelector(DialogsSelectors.clientCreate)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openClientCreateDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeClientCreateDialog())
    }, [dispatch]),
  }
}

export function useClientDeleteDialog() {
  const state = useSelector(DialogsSelectors.clientDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (client: Company) => {
        dispatch(dialogsSlice.actions.openClientDeleteDialog({ client }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeClientDeleteDialog())
    }, [dispatch]),
  }
}

export function useClientAcceptDialog() {
  const state = useSelector(DialogsSelectors.clientAccept)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (client: Company) => {
        dispatch(dialogsSlice.actions.openClientAcceptDialog({ client }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeClientAcceptDialog())
    }, [dispatch]),
  }
}

export function useTagEditDialog() {
  const state = useSelector(DialogsSelectors.tagEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (tag: Tag) => {
        dispatch(dialogsSlice.actions.openTagEditDialog({ tag }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTagEditDialog())
    }, [dispatch]),
  }
}

export function useTagDeleteDialog() {
  const state = useSelector(DialogsSelectors.tagDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (tag: Tag) => {
        dispatch(dialogsSlice.actions.openTagDeleteDialog({ tag }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTagDeleteDialog())
    }, [dispatch]),
  }
}

export function usePasswordChangeDialog() {
  const state = useSelector(DialogsSelectors.passwordChange)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (user: User) => {
        dispatch(dialogsSlice.actions.openPasswordChangeDialog({ user }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closePasswordChangeDialog())
    }, [dispatch]),
  }
}

export function useUserImageSettingDialog() {
  const state = useSelector(DialogsSelectors.userImageSetting)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (user: User) => {
        dispatch(dialogsSlice.actions.openUserImageSettingDialog({ user }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeUserImageSettingDialog())
    }, [dispatch]),
  }
}

export function useEmailChangeDialog() {
  const state = useSelector(DialogsSelectors.emailChange)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (email: string) => {
        dispatch(dialogsSlice.actions.openEmailChangeDialog({ email }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeEmailChangeDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceGroupCreateDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceGroupCreate)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (parentFolder: MoneyspaceFolder | null) => {
        dispatch(dialogsSlice.actions.openMoneyspaceGroupCreateDialog({ parentFolder }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceGroupCreateDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceGroupEditDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceGroupEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceFolder: MoneyspaceFolder) => {
        dispatch(dialogsSlice.actions.openMoneyspaceGroupEditDialog({ moneyspaceFolder }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceGroupEditDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceFolderMoveDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceFolderMove)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (folder: MoneyspaceFolder) => {
        dispatch(dialogsSlice.actions.openMoneyspaceFolderMoveDialog({ folder }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceFolderMoveDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceGroupDeleteDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceGroupDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceFolder: MoneyspaceFolder) => {
        dispatch(dialogsSlice.actions.openMoneyspaceGroupDeleteDialog({ moneyspaceFolder }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceGroupDeleteDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceCreateDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceCreate)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (folder: MoneyspaceFolder | null) => {
        dispatch(dialogsSlice.actions.openMoneyspaceCreateDialog({ folder }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceCreateDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceEditDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace?: Moneyspace) => {
        if (moneyspace) {
          dispatch(dialogsSlice.actions.openMoneyspaceEditDialog({ moneyspace }))
        }
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceEditDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceMoveDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceMove)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace?: Moneyspace) => {
        if (moneyspace) {
          dispatch(dialogsSlice.actions.openMoneyspaceMoveDialog({ moneyspace }))
        }
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceMoveDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceDeleteDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace?: Moneyspace) => {
        if (moneyspace) {
          dispatch(dialogsSlice.actions.openMoneyspaceDeleteDialog({ moneyspace }))
        }
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceDeleteDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceMembersSettingDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceMembersSetting)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace?: Moneyspace) => {
        if (moneyspace) {
          dispatch(dialogsSlice.actions.openMoneyspaceMembersSettingDialog({ moneyspace }))
        }
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceMembersSettingDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceClientEditDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceClientEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace?: Moneyspace) => {
        if (moneyspace) {
          dispatch(dialogsSlice.actions.openMoneyspaceClientEditDialog({ moneyspace }))
        }
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceClientEditDialog())
    }, [dispatch]),
  }
}

export function useMoneyspaceViewDialog() {
  const state = useSelector(DialogsSelectors.moneyspaceView)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace?: Moneyspace) => {
        if (moneyspace) {
          dispatch(dialogsSlice.actions.openMoneyspaceViewDialog({ moneyspace }))
        }
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMoneyspaceViewDialog())
    }, [dispatch]),
  }
}

export function useDocumentDeleteDialog() {
  const state = useSelector(DialogsSelectors.documentDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (document: Document) => {
        dispatch(dialogsSlice.actions.openDocumentDeleteDialog({ document }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentDeleteDialog())
    }, [dispatch]),
  }
}

export function useDocumentApplyDialog() {
  const state = useSelector(DialogsSelectors.documentApply)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (document: Document) => {
        dispatch(dialogsSlice.actions.openDocumentApplyDialog({ document }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentApplyDialog())
    }, [dispatch]),
  }
}

export function useDocumentApprovalFlowSettingDialog() {
  const state = useSelector(DialogsSelectors.documentApproveFlowSetting)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (document: Document) => {
        dispatch(dialogsSlice.actions.openDocumentApprovalFlowSettingDialog({ document }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentApprovalFlowSettingDialog())
    }, [dispatch]),
  }
}

export function useDocumentApproveDialog() {
  const state = useSelector(DialogsSelectors.documentApprove)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceId: string, contract: Contract, document: Document) => {
        dispatch(dialogsSlice.actions.openDocumentApproveDialog({ moneyspaceId, contract, document }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentApproveDialog())
    }, [dispatch]),
  }
}

export function useDocumentBulkApproveDialog() {
  const state = useSelector(DialogsSelectors.documentBulkApprove)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (documentIds: string[], page: number, sortColumn?: string, sortDirection?: SortDirection) => {
        dispatch(dialogsSlice.actions.openDocumentBulkApproveDialog({ documentIds, page, sortColumn, sortDirection }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentBulkApproveDialog())
    }, [dispatch]),
  }
}

export function useDocumentRejectDialog() {
  const state = useSelector(DialogsSelectors.documentReject)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (document: Document) => {
        dispatch(dialogsSlice.actions.openDocumentRejectDialog({ document }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentRejectDialog())
    }, [dispatch]),
  }
}

export function useDocumentCsvImportItemPreviewDialog() {
  const state = useSelector(DialogsSelectors.documentCsvImportItemPreview)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (title: string, items: DocumentItem[]) => {
        dispatch(dialogsSlice.actions.openDocumentCsvImportItemPreviewDialog({ title, items }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentCsvImportItemPreviewDialog())
    }, [dispatch]),
  }
}

export function useDocumentDiffDialog() {
  const state = useSelector(DialogsSelectors.documentDiff)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (
        moneyspace: Moneyspace,
        contract: Contract,
        document: Document,
        previousDocument: DocumentAttributes,
        changeLogs: ChangeLog[],
        approvalFlowState?: ApprovalFlowState
      ) => {
        dispatch(
          dialogsSlice.actions.openDocumentDiffDialog({
            moneyspace,
            contract,
            document,
            previousDocument,
            approvalFlowState,
            changeLogs,
          })
        )
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentDiffDialog())
    }, [dispatch]),
  }
}

export function useDocumentSearchConditionListDialog() {
  const state = useSelector(DialogsSelectors.documentSearchConditionList)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openDocumentSearchConditionListDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentSearchConditionListDialog())
    }, [dispatch]),
  }
}

export function useDocumentSearchConditionFormDialog() {
  const state = useSelector(DialogsSelectors.documentSearchConditionForm)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (id?: string) => {
        dispatch(dialogsSlice.actions.openDocumentSearchConditionFormDialog({ id }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentSearchConditionFormDialog())
    }, [dispatch]),
  }
}

export function useDocumentSearchConditionDeleteDialog() {
  const state = useSelector(DialogsSelectors.documentSearchConditionDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (id: string) => {
        dispatch(dialogsSlice.actions.openDocumentSearchConditionDeleteDialog({ id }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeDocumentSearchConditionDeleteDialog())
    }, [dispatch]),
  }
}

export function useTransactionCreateDialog() {
  const state = useSelector(DialogsSelectors.transactionCreate)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceId: string) => {
        dispatch(dialogsSlice.actions.openTransactionCreateDialog({ moneyspaceId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionCreateDialog())
    }, [dispatch]),
  }
}

export function useTransactionCancelDialog() {
  const state = useSelector(DialogsSelectors.transactionCancel)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transaction: Transaction) => {
        dispatch(dialogsSlice.actions.openTransactionCancelDialog({ transaction }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionCancelDialog())
    }, [dispatch]),
  }
}

export function useTransactionDeleteDialog() {
  const state = useSelector(DialogsSelectors.transactionDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transaction: Transaction) => {
        dispatch(dialogsSlice.actions.openTransactionDeleteDialog({ transaction }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionDeleteDialog())
    }, [dispatch]),
  }
}

export function useTransactionApplyDialog() {
  const state = useSelector(DialogsSelectors.transactionApply)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transaction: Transaction, installment?: TransactionInstallmentParams) => {
        dispatch(dialogsSlice.actions.openTransactionApplyDialog({ transaction, installment }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionApplyDialog())
    }, [dispatch]),
  }
}

export function useTransactionSubmitDialog() {
  const state = useSelector(DialogsSelectors.transactionSubmit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transaction: Transaction) => {
        dispatch(dialogsSlice.actions.openTransactionSubmitDialog({ transaction }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionSubmitDialog())
    }, [dispatch]),
  }
}

export function useTransactionApproveDialog() {
  const state = useSelector(DialogsSelectors.transactionApprove)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceId: string, transaction: Transaction) => {
        dispatch(dialogsSlice.actions.openTransactionApproveDialog({ moneyspaceId, transaction }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionApproveDialog())
    }, [dispatch]),
  }
}

export function useTransactionBulkApproveDialog() {
  const state = useSelector(DialogsSelectors.transactionBulkApprove)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transactionIds: string[], page: number, sortColumn?: string, sortDirection?: SortDirection) => {
        dispatch(
          dialogsSlice.actions.openTransactionBulkApproveDialog({ transactionIds, page, sortColumn, sortDirection })
        )
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionBulkApproveDialog())
    }, [dispatch]),
  }
}

export function useTransactionApprovalFlowSettingDialog() {
  const state = useSelector(DialogsSelectors.transactionApprovalFlowStatus)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transaction: Transaction) => {
        dispatch(dialogsSlice.actions.openTransactionApprovalFlowSettingDialog({ transaction }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionApprovalFlowSettingDialog())
    }, [dispatch]),
  }
}

export function useTransactionsApprovalFlowSettingDialog() {
  const state = useSelector(DialogsSelectors.transactionsApprovalFlowStatus)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transactions: TransactionSearchResultItem[]) => {
        dispatch(dialogsSlice.actions.openTransactionsApprovalFlowSettingDialog({ transactions }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionsApprovalFlowSettingDialog())
    }, [dispatch]),
  }
}

export function useTransactionRejectDialog() {
  const state = useSelector(DialogsSelectors.transactionReject)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transaction: Transaction) => {
        dispatch(dialogsSlice.actions.openTransactionRejectDialog({ transaction }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionRejectDialog())
    }, [dispatch]),
  }
}
export function useTransactionPhaseDiffDialog() {
  const state = useSelector(DialogsSelectors.transactionPhaseDiff)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (
        moneyspace: Moneyspace,
        transaction: Transaction,
        previousPhase: Transaction,
        changeLogs: PhaseChangeLog[],
        processable: boolean,
        approvalFlowState?: ApprovalFlowState
      ) => {
        dispatch(
          dialogsSlice.actions.openTransactionPhaseDiffDialog({
            moneyspace,
            transaction,
            previousPhase,
            approvalFlowState,
            changeLogs,
            processable,
          })
        )
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionPhaseDiffDialog())
    }, [dispatch]),
  }
}

export function useTransactionSearchConditionListDialog() {
  const state = useSelector(DialogsSelectors.transactionSearchConditionList)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openTransactionSearchConditionListDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionSearchConditionListDialog())
    }, [dispatch]),
  }
}

export function useTransactionSearchConditionFormDialog() {
  const state = useSelector(DialogsSelectors.transactionSearchConditionForm)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (id?: string) => {
        dispatch(dialogsSlice.actions.openTransactionSearchConditionFormDialog({ id }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionSearchConditionFormDialog())
    }, [dispatch]),
  }
}

export function useTransactionSearchConditionDeleteDialog() {
  const state = useSelector(DialogsSelectors.transactionSearchConditionDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (id: string) => {
        dispatch(dialogsSlice.actions.openTransactionSearchConditionDeleteDialog({ id }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionSearchConditionDeleteDialog())
    }, [dispatch]),
  }
}

export function useTransactionCopyDialog() {
  const state = useSelector(DialogsSelectors.transactionCopy)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transactionId: string, name: string) => {
        dispatch(dialogsSlice.actions.openTransactionCopyDialog({ transactionId, name }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionCopyDialog())
    }, [dispatch]),
  }
}

export function useTransactionReleaseDialog() {
  const state = useSelector(DialogsSelectors.transactionRelease)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceId: string, transactionId: string) => {
        dispatch(dialogsSlice.actions.openTransactionReleaseDialog({ moneyspaceId, transactionId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionReleaseDialog())
    }, [dispatch]),
  }
}

export function useBillingPaidDialog() {
  const state = useSelector(DialogsSelectors.billingPaid)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspaceId: string, billingId: string) => {
        dispatch(dialogsSlice.actions.openBillingPaidDialog({ moneyspaceId, billingId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeBillingPaidDialog())
    }, [dispatch]),
  }
}

export function useBillingBulkPaidDialog() {
  const state = useSelector(DialogsSelectors.billingBulkPaid)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (billingIds: string[], searchParams: BillingSearchParams) => {
        dispatch(dialogsSlice.actions.openBillingBulkPaidDialog({ billingIds, searchParams }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeBillingBulkPaidDialog())
    }, [dispatch]),
  }
}

export function useContractEditDialog() {
  const state = useSelector(DialogsSelectors.contractEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (contract: Contract) => {
        dispatch(dialogsSlice.actions.openContractEditDialog({ contract }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeContractEditDialog())
    }, [dispatch]),
  }
}

export function useContractDeleteDialog() {
  const state = useSelector(DialogsSelectors.contractDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (contract: Contract, moneyspaceId: string) => {
        dispatch(dialogsSlice.actions.openContractDeleteDialog({ contract, moneyspaceId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeContractDeleteDialog())
    }, [dispatch]),
  }
}

export function useContractCopyDialog() {
  const state = useSelector(DialogsSelectors.contractCopy)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (moneyspace: Moneyspace, contract: Contract, page: number, excludeClosed?: boolean) => {
        dispatch(dialogsSlice.actions.openContractCopyDialog({ moneyspace, contract, page, excludeClosed }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeContractCopyDialog())
    }, [dispatch]),
  }
}

export function useAttachmentsViewDialog() {
  const state = useSelector(DialogsSelectors.attachmentsView)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (activeId: string, attachments: Attachment[]) => {
        dispatch(dialogsSlice.actions.openAttachmentsViewDialog({ activeId, attachments }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeAttachmentsViewDialog())
    }, [dispatch]),
  }
}

export function useAttachmentDeleteDialog() {
  const state = useSelector(DialogsSelectors.attachmentDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (contractId: string, attachment: Attachment) => {
        dispatch(dialogsSlice.actions.openAttachmentDeleteDialog({ contractId, attachment }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeAttachmentDeleteDialog())
    }, [dispatch]),
  }
}

export function useApprovalFlowSettingDialog() {
  const state = useSelector(DialogsSelectors.approvalFlowSetting)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (flow?: ApprovalFlow) => {
        dispatch(dialogsSlice.actions.openApprovalFlowSettingDialog({ flow }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeApprovalFlowSettingDialog())
    }, [dispatch]),
  }
}

export function useApprovalFlowDeleteDialog() {
  const state = useSelector(DialogsSelectors.approvalFlowDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (flow: ApprovalFlow) => {
        dispatch(dialogsSlice.actions.openApprovalFlowDeleteDialog({ flow }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeApprovalFlowDeleteDialog())
    }, [dispatch]),
  }
}

export function useTransactionClientViewDialog() {
  const state = useSelector(DialogsSelectors.transactionClientView)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (company: CompanyAttributes, isContractee: boolean) => {
        dispatch(dialogsSlice.actions.openTransactionClientViewDialog({ company, isContractee }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionClientViewDialog())
    }, [dispatch]),
  }
}

export function useTransactionCsvImportItemPreviewDialog() {
  const state = useSelector(DialogsSelectors.transactionCsvImportItemPreview)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (title: string, siteAddress: string, details: TransactionDetail[]) => {
        dispatch(dialogsSlice.actions.openTransactionCsvImportItemPreviewDialog({ title, siteAddress, details }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionCsvImportItemPreviewDialog())
    }, [dispatch]),
  }
}

export function useTransactionPdfPreviewDialog() {
  const state = useSelector(DialogsSelectors.transactionPdfPreview)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (transactionId: string, phase: TransactionPhase) => {
        dispatch(dialogsSlice.actions.openTransactionPdfPreviewDialog({ transactionId, phase }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeTransactionPdfPreviewDialog())
    }, [dispatch]),
  }
}

export function useBillingPdfPreviewDialog() {
  const state = useSelector(DialogsSelectors.billingPdfPreview)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (billingId: string) => {
        dispatch(dialogsSlice.actions.openBillingPdfPreviewDialog({ billingId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeBillingPdfPreviewDialog())
    }, [dispatch]),
  }
}

export function useMessageCreateDialog() {
  const state = useSelector(DialogsSelectors.messageCreate)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openMessageCreateDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMessageCreateDialog())
    }, [dispatch]),
  }
}

export function useMessageEditDialog() {
  const state = useSelector(DialogsSelectors.messageEdit)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (message: ReservedMessage) => {
        dispatch(dialogsSlice.actions.openMessageEditDialog({ message }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMessageEditDialog())
    }, [dispatch]),
  }
}

export function useMessageDeleteDialog() {
  const state = useSelector(DialogsSelectors.messageDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (message: ReservedMessage) => {
        dispatch(dialogsSlice.actions.openMessageDeleteDialog({ message }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeMessageDeleteDialog())
    }, [dispatch]),
  }
}

export function useReservedMessageDialog() {
  const state = useSelector(DialogsSelectors.reservedMessage)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openReservedMessageDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeReservedMessageDialog())
    }, [dispatch]),
  }
}

export function useJobWorkLocationSettingDialog() {
  const state = useSelector(DialogsSelectors.jobWorkLocationSetting)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openJobWorkLocationSettingDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeJobWorkLocationSettingDialog())
    }, [dispatch]),
  }
}

export function useJobImageSettingDialog() {
  const state = useSelector(DialogsSelectors.jobImageSetting)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(() => {
      dispatch(dialogsSlice.actions.openJobImageSettingDialog({}))
    }, [dispatch]),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeJobImageSettingDialog())
    }, [dispatch]),
  }
}

export function useJobImageDeleteDialog() {
  const state = useSelector(DialogsSelectors.jobImageDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (imageId: string) => {
        dispatch(dialogsSlice.actions.openJobImageDeleteDialog({ imageId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeJobImageDeleteDialog())
    }, [dispatch]),
  }
}

export function useJobOfferDeleteDialog() {
  const state = useSelector(DialogsSelectors.jobOfferDelete)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (jobOfferId: string) => {
        dispatch(dialogsSlice.actions.openJobOfferDeleteDialog({ jobOfferId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeJobOfferDeleteDialog())
    }, [dispatch]),
  }
}

export function useJobOfferPublishedStatusChangeDialog() {
  const state = useSelector(DialogsSelectors.jobOfferPublishedStatusChange)
  const dispatch = useDispatch()
  return {
    state,
    open: useCallback(
      (jobOfferId: string) => {
        dispatch(dialogsSlice.actions.openJobOfferPublishedStatusChangeDialog({ jobOfferId }))
      },
      [dispatch]
    ),
    close: useCallback(() => {
      dispatch(dialogsSlice.actions.closeJobOfferPublishedStatusChangeDialog())
    }, [dispatch]),
  }
}
