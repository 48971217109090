export default function useConfig() {
  const documentImportCsvFormatUrl =
    'https://monect-prd-public-contents.s3.ap-northeast-1.amazonaws.com/templates/%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%83%E3%83%88%EF%BC%88%E4%B8%80%E6%8B%AC%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%EF%BC%89.csv'
  const documentImportCsvSampleUrl =
    'https://monect-prd-public-contents.s3.ap-northeast-1.amazonaws.com/templates/%E3%82%B5%E3%83%B3%E3%83%95%E3%82%9A%E3%83%AB%EF%BC%88%E6%9B%B8%E9%A1%9E%E3%81%AE%E4%B8%80%E6%8B%AC%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%EF%BC%89.csv'
  const documentImportSamplePdfUrl =
    'https://monect-prd-public-contents.s3.ap-northeast-1.amazonaws.com/templates/%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%82%B5%E3%83%B3%E3%83%97%E3%83%AB%E3%81%AE%E8%AA%AC%E6%98%8E.pdf'
  const enableTransactionImport = true
  const enableJob = process.env.REACT_APP_ENABLE_JOB === 'true'
  return {
    documentImportCsvFormatUrl,
    documentImportCsvSampleUrl,
    documentImportSamplePdfUrl,
    enableTransactionImport,
    enableJob,
  }
}
