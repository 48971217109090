import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import Label from '../components/atoms/display/Label'
import DashboardCard from '../components/molecules/display/DashboardCard'
import {
  AggregationIcon,
  BillingIcon,
  ContractIcon,
  DeliveredIcon,
  MessageIcon,
  PaymentIcon,
  PendingApprovalDocumentIcon,
  ReceivedDocumentIcon,
} from '../components/atoms/display/Icons'
import useRouter from '../hooks/useRouter'
import usePage from '../hooks/usePage'
import { DashboardActions } from '../store/dashboard'
import { DashboardSelectors } from '../store/dashboard/selectors'
import { CompanySelectors } from '../store/company/selectors'

export default function DashboardPage() {
  const {
    pendingTransactions,
    receivedTransactions,
    deliveredTransactions,
    messages,
    paymentBillings,
    activeTransactions,
    billingTransactions,
    aggregation,
  } = useRouter()
  const { changeTitle } = usePage()
  const dispatch = useDispatch()
  const data = useSelector(DashboardSelectors.data)
  const isRS = useSelector(CompanySelectors.isRS)
  useEffect(() => {
    changeTitle('ダッシュボード')
  }, [changeTitle])
  useEffect(() => {
    dispatch(DashboardActions.fetchDashboard())
  }, [dispatch])
  return (
    <FlexColumn>
      <FlexRow space={4} wrap>
        <FlexColumn>
          <Label text="取引" variant="subtitle" />
          <FlexRow wrap>
            <DashboardCard
              label="あなたの承認待ち"
              icon={<PendingApprovalDocumentIcon size={64} />}
              count={data.documents.count.unapproved}
              onClick={() => pendingTransactions()}
            />
            <DashboardCard
              label="社内確認待ち"
              icon={<ReceivedDocumentIcon size={64} />}
              count={data.documents.count.submitted}
              onClick={() => receivedTransactions()}
            />
          </FlexRow>
        </FlexColumn>
        <FlexColumn>
          <Label text="通知" variant="subtitle" />
          <FlexRow wrap>
            <DashboardCard
              label="メッセージ"
              icon={<MessageIcon size={64} />}
              count={data.messages.count.unread}
              hasNew={data.messages.hasToMentioned}
              onClick={() => messages()}
            />
          </FlexRow>
        </FlexColumn>
      </FlexRow>
      <FlexColumn>
        <Label text="その他" variant="subtitle" />
        <FlexRow wrap>
          <DashboardCard label="支払い" icon={<PaymentIcon size={64} />} count={0} onClick={() => paymentBillings()} />
          <DashboardCard
            label="進行中の取引"
            icon={<ContractIcon size={64} />}
            count={0}
            onClick={() => activeTransactions()}
          />
          {isRS ? (
            <DashboardCard
              label="完納"
              icon={<DeliveredIcon size={64} />}
              count={0}
              onClick={() => deliveredTransactions()}
            />
          ) : (
            <DashboardCard
              label="請求の確認"
              icon={<BillingIcon size={64} />}
              count={0}
              onClick={() => billingTransactions()}
            />
          )}
          <DashboardCard label="集計" icon={<AggregationIcon size={64} />} count={0} onClick={() => aggregation()} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}
