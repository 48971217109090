import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { DocumentAttributes } from '../../../types/Document'
import useDocumentAmounts from '../../../hooks/useDocumentAmounts'
import Card from '../../atoms/surfaces/Card'
import { Moneyspace } from '../../../types/Moneyspace'
import { CompanySelectors } from '../../../store/company/selectors'

type DocumentAmountViewProps = {
  moneyspace: Moneyspace
  document: DocumentAttributes
  onError?: (error: boolean) => void
}

export default function DocumentAmountView({ moneyspace, document, onError }: DocumentAmountViewProps) {
  const { membershipFee, subTotal, tax10Percent, tax8Percent, subTotal10Percent, subTotal8Percent, total } =
    useDocumentAmounts(moneyspace, document)
  const membershipFeeName = useSelector(CompanySelectors.membershipFeeName)
  if (onError) {
    onError(total > 99900000000)
  }

  return (
    <FlexColumn>
      <FlexRow justify="space-between">
        <FlexColumn align="flex-end">
          <Label text="小計" />
          <Label text="消費税※" />
          <>{membershipFee !== undefined && <Label text={membershipFeeName} />}</>
          <Label text="合計金額" />
        </FlexColumn>
        <FlexColumn align="flex-end">
          <Label text={subTotal} format="amount" />
          <Label text={tax10Percent + tax8Percent} format="amount" />
          <>{membershipFee !== undefined && <Label text={membershipFee} format="amount" />}</>
          <Label text={total} format="amount" />
        </FlexColumn>
      </FlexRow>
      <FlexRow justify="flex-end">
        {total > 99900000000 && (
          <Label text="書類の合計金額の上限は99,900,000,000円です" color="error" variant="caption" />
        )}
      </FlexRow>
      <Card>
        <FlexColumn>
          <Label text="※消費税内訳" />
          <FlexRow justify="space-between">
            <FlexColumn align="flex-end">
              <Label text="10%対象" />
              <Label text="8%対象" />
            </FlexColumn>
            <FlexColumn align="flex-end">
              <Label text={subTotal10Percent} format="amount" />
              <Label text={subTotal8Percent} format="amount" />
            </FlexColumn>
            <FlexColumn align="flex-end">
              <Label text="消費税" />
              <Label text="消費税" />
            </FlexColumn>
            <FlexColumn align="flex-end">
              <Label text={tax10Percent} format="amount" />
              <Label text={tax8Percent} format="amount" />
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
      </Card>
    </FlexColumn>
  )
}
