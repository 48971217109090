import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const transactionsSelector = (state: StoreState) => state.transactions
export const TransactionSelectors = {
  transactions: createSelector([transactionsSelector], (state) => state.transactions),
  transaction: createSelector([transactionsSelector], (state) => state.transaction),
  installmentTransactions: createSelector([transactionsSelector], (state) => state.installmentTransactions),
  selectedTransaction: createSelector([transactionsSelector], (state) => state.selectedTransaction),
  activities: createSelector([transactionsSelector], (state) => state.activities),
  approvalFlowState: createSelector([transactionsSelector], (state) => state.approvalFlowState),
  factoringTransactions: createSelector([transactionsSelector], (state) => state.factoringTransactions),
  previousPhase: createSelector([transactionsSelector], (state) => state.previousPhase),
  searchParams: createSelector([transactionsSelector], (state) => state.searchParams),
  searchingTransactions: createSelector([transactionsSelector], (state) => state.searchingTransactions),
  searchTransactionResult: createSelector([transactionsSelector], (state) => state.searchTransactionResult),
  searchTransactionDetailResult: createSelector([transactionsSelector], (state) => state.searchTransactionDetailResult),
  searchBillingParams: createSelector([transactionsSelector], (state) => state.searchBillingParams),
  searchingBillings: createSelector([transactionsSelector], (state) => state.searchingBillings),
  searchBillingResult: createSelector([transactionsSelector], (state) => state.searchBillingResult),
  billings: createSelector([transactionsSelector], (state) => state.billings),
  searchConditions: createSelector([transactionsSelector], (state) => state.searchConditions),
  unapprovedTransactions: createSelector([transactionsSelector], (state) => state.unapprovedTransactions),
  submittedTransactions: createSelector([transactionsSelector], (state) => state.submittedTransactions),
  deliveredTransactions: createSelector([transactionsSelector], (state) => state.deliveredTransactions),
  importFailureDeliveredTransactions: createSelector(
    [transactionsSelector],
    (state) => state.importFailureDeliveredTransactions
  ),
  activeTransactions: createSelector([transactionsSelector], (state) => state.activeTransactions),
  billingTransactions: createSelector([transactionsSelector], (state) => state.billingTransactions),
  aggregateTransactions: createSelector([transactionsSelector], (state) => state.aggregateTransactions),

  contracts: createSelector([transactionsSelector], (state) => state.contracts),
  contract: createSelector([transactionsSelector], (state) => state.contract),
  attachments: createSelector([transactionsSelector], (state) => state.attachments),
  contractSummaries: createSelector([transactionsSelector], (state) => state.contractSummaries),
}
