import { useSelector } from 'react-redux'
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form'
import { SystemSelectors } from '../../../store/system/selectors'
import SelectControl, { SelectItem } from '../../atoms/inputs/SelectControl'

type Props<T extends FieldValues> = {
  name: FieldPath<T>
  error?: string
}

export default function WorkTypeSelect<T extends FieldValues>({ name, error }: Props<T>) {
  const form = useFormContext()
  const consts = useSelector(SystemSelectors.consts)
  const items: SelectItem[] = consts?.jobWorkType.map((type) => ({ value: type.id, label: type.text })) ?? []
  return <SelectControl items={items} size="sm" control={form.control} name={name} error={error} />
}
