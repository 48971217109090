import { Company, CompanyAttributes, CompanyMembershipFee, DetailPropConfig, TransactionPropConfig } from './Company'
import { RelatedUser } from './User'

// 協力会費
export type MembershipFee = {
  rate: number
  isTaxIn: boolean
}

export type Payment = {
  closingDay?: number
  paymentMonth: number
  paymentDay?: number
  bearingFee?: boolean
  isTaxIn: boolean
}

export const InvoiceLessPatternA = 1
export const InvoiceLessPatternB = 2 // 需要がないため一旦未使用
export const InvoiceLessPatternC = 3

export type Moneyspace = {
  id: string
  name: string
  contractee?: Company
  contractor?: Company
  createdBy: RelatedUser
  isContractee: boolean
  isDeletable: boolean
  isPinned: boolean
  client: CompanyAttributes | null
  unreadMessageCount?: number
  membershipFee: CompanyMembershipFee
  aggregateTransactions: boolean
  fixMonth: number | null
  fixDay: number | null
  alertBillingDeadline: boolean
  detailPropConfig: DetailPropConfig
  transactionPropConfig: TransactionPropConfig
  fixGracePeriod?: number
  taxRoundingType?: number
  invoiceLessActionType?: number | null
  externalId?: string | null
} & Payment

export type MoneyspaceWithLatestMessage = Moneyspace & {
  latestMessage: {
    description: string
    createdBy: RelatedUser
    createdAt: string
  }
}

export type MoneyspaceFolder = {
  id: string
  name: string
  children: MoneyspaceFolder[]
  msIds: string[]
}

export type MoneyspaceGroup = {
  id: string
  name: string
  moneyspaces: Moneyspace[]
}

export type PaymentFormParams = {
  closingDay?: number
  paymentMonth: number
  paymentDay?: number
  bearingFee?: boolean
  isTaxIn: boolean
  fixGracePeriod?: number
  taxRoundingType?: number
}

export type MoneyspaceCreateParams = {
  name: string
  isContractee: string
  invitee?: string
  membershipFee: 1 | 2
} & PaymentFormParams

export type MoneyspaceEditParams = {
  name: string
  alertBillingDeadline: boolean
  externalId: string | null
} & PaymentFormParams

export type MoneyspacePosition = 'contractee' | 'contractor'

export type MembershipTaxIn = 1 /* 税抜 */ | 2 /* 税込 */
export type MembershipFeeType = 1 /* 金額 */ | 2 /* 料率 */

export type MembershipFee2 = {
  name: string
  taxIn: MembershipTaxIn
  type: MembershipFeeType
  conditions: MembershipFeeCondition[]
  maximumFee: number | null
  roundingType: number
}

export type MembershipFeeCondition = {
  amount: number
  value: number
}

export function convertMembershipFee(companyMembershipFee: CompanyMembershipFee): MembershipFee2 {
  if (companyMembershipFee == null) {
    return {
      name: '協力会費',
      taxIn: 1,
      type: 2,
      conditions: [],
      maximumFee: null,
      roundingType: 1,
    }
  }
  return {
    name: companyMembershipFee.name,
    taxIn: companyMembershipFee.isTaxIn ? 2 : 1,
    type: companyMembershipFee.useRate ? 2 : 1,
    conditions: companyMembershipFee.chart.map((values) => ({ amount: values[1], value: values[2] })),
    maximumFee: companyMembershipFee.maximumFee,
    roundingType: companyMembershipFee.roundingType,
  }
}
