// selector
import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const storeSelector = (state: StoreState) => state
const systemSelector = (state: StoreState) => state.system
export const SystemSelectors = {
  pageTitle: createSelector([systemSelector], (state) => state.pageTitle),
  pageSubTitle: createSelector([systemSelector], (state) => state.pageSubTitle),
  error: createSelector([systemSelector], (state) => {
    if (state.requestResult) {
      return state.requestResult.message
    }
    return undefined
  }),
  initialized: createSelector([storeSelector], (state) => state.session.user && state.company.company),
  loading: createSelector([systemSelector], (state) => state.requestingCount > 0),
  loadingMessage: createSelector([systemSelector], (state) => state.loadingMessage),
  message: createSelector([systemSelector], (state) => state.message),
  redirectTo: createSelector([systemSelector], (state) => state.redirectTo),
  sidebar: createSelector([systemSelector], (state) => state.sidebar),
  processing: createSelector([systemSelector], (state) => state.processing !== undefined),
  maintenanceInfo: createSelector([systemSelector], (state) => state.maintenanceInfo),
  consts: createSelector([systemSelector], (state) => state.consts),
}
