import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useJobImageDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import { JobActions } from '../../../store/job'
import { JobSelectors } from '../../../store/job/selectors'
import Image from '../../atoms/display/Image'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'

export default function JobImageDeleteDialog() {
  const { state, close } = useJobImageDeleteDialog()
  const dispatch = useDispatch()
  const images = useSelector(JobSelectors.images)
  const jobImage = images.find((image) => image.id === state?.imageId)

  const handleDeleteImage = useCallback(() => {
    if (state) {
      dispatch(JobActions.deleteImage({ id: state.imageId }))
    }
  }, [dispatch, state])

  if (!jobImage) {
    return null
  }
  return (
    <Dialog
      open={state !== undefined}
      title="求人画像削除"
      width="xs"
      actions={
        <Button color="caution" onClick={handleDeleteImage}>
          削除
        </Button>
      }
      onClose={close}
    >
      <FlexColumn maxHeight="60vh">
        <Image url={jobImage.url} alt={jobImage.fileName} width={300} height={188} />
        <Label text="この画像を削除しますか？" />
      </FlexColumn>
    </Dialog>
  )
}
