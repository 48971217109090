import NumberFormat from 'react-number-format'
import { TextField } from '@mui/material'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useDeviceType } from '../../../hooks/useDeviceType'

type NumberFieldType = 'number' | 'amount'
type TextSize = 'xs' | 'sm' | 'md' | 'lg'

type NumberControlProps<T extends FieldValues> = {
  type?: NumberFieldType
  size?: TextSize
  readonly?: boolean
  placeholder?: string
  name: FieldPath<T>
  control: Control<T>
  error?: string
  suffix?: string
  disabled?: boolean
  nonConversion?: boolean
}

const width = (size?: TextSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'md':
      return 360
    case 'lg':
      return 480
    default:
      return '100%'
  }
}

function AsciiTextField(props: TextFieldProps) {
  const { onChange } = props
  const { pc } = useDeviceType()
  return (
    <TextField
      {...props}
      onChange={(e) => {
        if (onChange) {
          if (pc) {
            // PCのみ全角->半角変換に対応
            const nativeEvent = e.nativeEvent as InputEvent
            if (nativeEvent.isComposing && nativeEvent.data?.match(/[０-９]/g)) {
              e.target.value = e.target.value.replace(/[０-９]/g, (char: string) =>
                String.fromCharCode(char.charCodeAt(0) - 0xfee0)
              )
            }
          }
          onChange(e)
        }
      }}
    />
  )
}

function NonConversionAsciiTextField(props: TextFieldProps) {
  const { onChange } = props
  return (
    <TextField
      {...props}
      onChange={(e) => {
        if (onChange) {
          onChange(e)
        }
      }}
    />
  )
}

export default function NumberControl<T extends FieldValues>({
  type,
  size,
  readonly,
  placeholder,
  name,
  control,
  error,
  suffix,
  disabled,
  nonConversion,
}: NumberControlProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <NumberFormat
          {...field}
          onValueChange={(newValue) => {
            field.onChange(newValue.floatValue ?? null)
          }}
          onChange={undefined}
          size="small"
          placeholder={placeholder}
          sx={{ width: width(size), textAlign: 'right' }}
          thousandSeparator={type === 'amount'}
          customInput={nonConversion ? NonConversionAsciiTextField : AsciiTextField}
          InputProps={{
            readOnly: readonly,
          }}
          error={error !== undefined}
          helperText={error ?? ''}
          suffix={suffix}
          disabled={disabled}
          inputMode="decimal"
        />
      )}
    />
  )
}
