import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../types/User'
import { Company, CompanyAttributes } from '../../types/Company'
import { Tag, Document, Contract, Attachment, DocumentItem, ChangeLog, DocumentAttributes } from '../../types/Document'
import { CompanyActions } from '../company'
import { DocumentsActions } from '../documents'
import { SessionActions } from '../session'
import { Moneyspace, MoneyspaceFolder } from '../../types/Moneyspace'
import { MoneyspacesActions } from '../moneyspaces'
import { TransactionActions } from '../transactions'
import { ApprovalFlow, ApprovalFlowState } from '../../types/ApprovalFlow'
import { ApprovalFlowActions } from '../approval_flow'
import { SortDirection } from '../../types/System'
import {
  PhaseChangeLog,
  Transaction,
  TransactionDetail,
  TransactionInstallmentParams,
  TransactionPhase,
  TransactionSearchResultItem,
} from '../../types/transaction'
import { BillingSearchParams } from '../../types/search'
import { ChatActions } from '../chat'
import { ReservedMessage } from '../../types/Chat'
import { JobActions } from '../job'

export type DialogsState = {
  userCreate?: {}
  userEdit?: { user: User }
  userDelete?: { user: User }
  clientCreate?: {}
  clientEdit?: { client: Company }
  clientDelete?: { client: Company }
  clientAccept?: { client: Company }
  tagEdit?: { tag: Tag }
  tagDelete?: { tag: Tag }
  passwordChange?: { user: User }
  emailChange?: { email: string }
  userImageSetting?: { user: User }
  moneyspaceGroupCreate?: { parentFolder: MoneyspaceFolder | null }
  moneyspaceGroupEdit?: { moneyspaceFolder: MoneyspaceFolder }
  moneyspaceFolderMove?: { folder: MoneyspaceFolder }
  moneyspaceGroupDelete?: { moneyspaceFolder: MoneyspaceFolder }
  moneyspaceCreate?: { folder: MoneyspaceFolder | null }
  moneyspaceEdit?: { moneyspace: Moneyspace }
  moneyspaceMove?: { moneyspace: Moneyspace }
  moneyspaceDelete?: { moneyspace: Moneyspace }
  moneyspaceMembersSetting?: { moneyspace: Moneyspace }
  moneyspaceClientEdit?: { moneyspace: Moneyspace }
  moneyspaceView?: { moneyspace: Moneyspace }
  documentDelete?: { document: Document }
  documentApply?: { document: Document }
  documentApprovalFlowSetting?: { document: Document }
  documentApprove?: { moneyspaceId: string; contract: Contract; document: Document }
  documentBulkApprove?: {
    documentIds: string[]
    page: number
    sortColumn?: string
    sortDirection?: SortDirection
  }
  documentReject?: { document: Document }
  documentCopy?: { document: Document; destinationDocument: Document }
  documentCsvImportItemPreview?: { title: string; items: DocumentItem[] }
  documentDiff?: {
    moneyspace: Moneyspace
    contract: Contract
    document: Document
    previousDocument: DocumentAttributes
    approvalFlowState?: ApprovalFlowState
    changeLogs: ChangeLog[]
  }
  documentSearchConditionList?: {}
  documentSearchConditionForm?: { id?: string }
  documentSearchConditionDelete?: { id: string }
  transactionCreate?: { moneyspaceId: string }
  transactionCancel?: { transaction: Transaction }
  transactionDelete?: { transaction: Transaction }
  transactionApply?: { transaction: Transaction; installment?: TransactionInstallmentParams }
  transactionSubmit?: { transaction: Transaction }
  transactionApprovalFlowSetting?: { transaction: Transaction }
  transactionsApprovalFlowSetting?: { transactions: TransactionSearchResultItem[] }
  transactionApprove?: { moneyspaceId: string; transaction: Transaction }
  transactionBulkApprove?: {
    transactionIds: string[]
    page: number
    sortColumn?: string
    sortDirection?: SortDirection
  }
  transactionReject?: { transaction: Transaction }
  transactionPhaseDiff?: {
    moneyspace: Moneyspace
    transaction: Transaction
    previousPhase: Transaction
    approvalFlowState?: ApprovalFlowState
    changeLogs: PhaseChangeLog[]
    processable: boolean
  }
  transactionSearchConditionList?: {}
  transactionSearchConditionForm?: { id?: string }
  transactionSearchConditionDelete?: { id: string }
  transactionCopy?: { transactionId: string; name: string }
  transactionClientView?: { company: CompanyAttributes; isContractee: boolean }
  transactionRelease?: { moneyspaceId: string; transactionId: string }
  billingPaid?: { moneyspaceId: string; billingId: string }
  billingBulkPaid?: { billingIds: string[]; searchParams: BillingSearchParams }
  transactionCsvImportItemPreview?: { title: string; siteAddress: string; details: TransactionDetail[] }
  transactionPdfPreview?: { transactionId: string; phase: TransactionPhase }
  billingPdfPreview?: { billingId: string }

  contractEdit?: { contract: Contract }
  contractDelete?: { contract: Contract; moneyspaceId: string }
  contractCopy?: { moneyspace: Moneyspace; contract: Contract; page: number; excludeClosed?: boolean }
  attachmentDelete?: { contractId: string; attachment: Attachment }
  attachmentsView?: { activeId: string; attachments: Attachment[] }
  approvalFlowSetting?: { flow?: ApprovalFlow }
  approvalFlowDelete?: { flow: ApprovalFlow }
  messageCreate?: {}
  messageEdit?: { message: ReservedMessage }
  messageDelete?: { message: ReservedMessage }
  reservedMessage?: {}
  jobWorkLocationSetting?: {}
  jobImageSetting?: {}
  jobImageDelete?: { imageId: string }
  jobOfferDelete?: { jobOfferId: string }
  jobOfferPublishedStatusChange?: { jobOfferId: string }
}

export const initialDialogsState: DialogsState = {
  userCreate: undefined,
  userEdit: undefined,
  userDelete: undefined,
  clientCreate: undefined,
  clientEdit: undefined,
  clientDelete: undefined,
  tagEdit: undefined,
  tagDelete: undefined,
  passwordChange: undefined,
  emailChange: undefined,
  userImageSetting: undefined,
  documentDelete: undefined,
  transactionCreate: undefined,
  contractEdit: undefined,
  contractDelete: undefined,
  attachmentDelete: undefined,
}

export const DialogsActions = {}

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState: initialDialogsState,
  reducers: {
    openUserCreateDialog: (state: DialogsState, action: PayloadAction<typeof state.userCreate>) => {
      state.userCreate = action.payload
    },
    closeUserCreateDialog: (state: DialogsState) => {
      state.userCreate = undefined
    },
    openUserEditDialog: (state: DialogsState, action: PayloadAction<typeof state.userEdit>) => {
      state.userEdit = action.payload
    },
    closeUserEditDialog: (state: DialogsState) => {
      state.userEdit = undefined
    },
    openUserDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.userDelete>) => {
      state.userDelete = action.payload
    },
    closeUserDeleteDialog: (state: DialogsState) => {
      state.userDelete = undefined
    },
    openClientCreateDialog: (state: DialogsState, action: PayloadAction<typeof state.clientCreate>) => {
      state.clientCreate = action.payload
    },
    closeClientCreateDialog: (state: DialogsState) => {
      state.clientCreate = undefined
    },
    openClientEditDialog: (state: DialogsState, action: PayloadAction<typeof state.clientEdit>) => {
      state.clientEdit = action.payload
    },
    closeClientEditDialog: (state: DialogsState) => {
      state.clientEdit = undefined
    },
    openClientDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.clientDelete>) => {
      state.clientDelete = action.payload
    },
    closeClientDeleteDialog: (state: DialogsState) => {
      state.clientDelete = undefined
    },
    openClientAcceptDialog: (state: DialogsState, action: PayloadAction<typeof state.clientAccept>) => {
      state.clientAccept = action.payload
    },
    closeClientAcceptDialog: (state: DialogsState) => {
      state.clientAccept = undefined
    },
    openTagEditDialog: (state: DialogsState, action: PayloadAction<typeof state.tagEdit>) => {
      state.tagEdit = action.payload
    },
    closeTagEditDialog: (state: DialogsState) => {
      state.tagEdit = undefined
    },
    openTagDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.tagDelete>) => {
      state.tagDelete = action.payload
    },
    closeTagDeleteDialog: (state: DialogsState) => {
      state.tagDelete = undefined
    },
    openPasswordChangeDialog: (state: DialogsState, action: PayloadAction<typeof state.passwordChange>) => {
      state.passwordChange = action.payload
    },
    closePasswordChangeDialog: (state: DialogsState) => {
      state.passwordChange = undefined
    },
    openEmailChangeDialog: (state: DialogsState, action: PayloadAction<typeof state.emailChange>) => {
      state.emailChange = action.payload
    },
    closeEmailChangeDialog: (state: DialogsState) => {
      state.emailChange = undefined
    },
    openUserImageSettingDialog: (state: DialogsState, action: PayloadAction<typeof state.userImageSetting>) => {
      state.userImageSetting = action.payload
    },
    closeUserImageSettingDialog: (state: DialogsState) => {
      state.userImageSetting = undefined
    },
    openMoneyspaceGroupCreateDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.moneyspaceGroupCreate>
    ) => {
      state.moneyspaceGroupCreate = action.payload
    },
    closeMoneyspaceGroupCreateDialog: (state: DialogsState) => {
      state.moneyspaceGroupCreate = undefined
    },
    openMoneyspaceGroupEditDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceGroupEdit>) => {
      state.moneyspaceGroupEdit = action.payload
    },
    closeMoneyspaceGroupEditDialog: (state: DialogsState) => {
      state.moneyspaceGroupEdit = undefined
    },
    openMoneyspaceFolderMoveDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceFolderMove>) => {
      state.moneyspaceFolderMove = action.payload
    },
    closeMoneyspaceFolderMoveDialog: (state: DialogsState) => {
      state.moneyspaceFolderMove = undefined
    },
    openMoneyspaceGroupDeleteDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.moneyspaceGroupDelete>
    ) => {
      state.moneyspaceGroupDelete = action.payload
    },
    closeMoneyspaceGroupDeleteDialog: (state: DialogsState) => {
      state.moneyspaceGroupDelete = undefined
    },
    openMoneyspaceCreateDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceCreate>) => {
      state.moneyspaceCreate = action.payload
    },
    closeMoneyspaceCreateDialog: (state: DialogsState) => {
      state.moneyspaceCreate = undefined
    },
    openMoneyspaceEditDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceEdit>) => {
      state.moneyspaceEdit = action.payload
    },
    closeMoneyspaceEditDialog: (state: DialogsState) => {
      state.moneyspaceEdit = undefined
    },
    openMoneyspaceMoveDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceMove>) => {
      state.moneyspaceMove = action.payload
    },
    closeMoneyspaceMoveDialog: (state: DialogsState) => {
      state.moneyspaceMove = undefined
    },
    openMoneyspaceDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceDelete>) => {
      state.moneyspaceDelete = action.payload
    },
    closeMoneyspaceDeleteDialog: (state: DialogsState) => {
      state.moneyspaceDelete = undefined
    },
    openMoneyspaceMembersSettingDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.moneyspaceMembersSetting>
    ) => {
      state.moneyspaceMembersSetting = action.payload
    },
    closeMoneyspaceMembersSettingDialog: (state: DialogsState) => {
      state.moneyspaceMembersSetting = undefined
    },
    openMoneyspaceClientEditDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceClientEdit>) => {
      state.moneyspaceClientEdit = action.payload
    },
    closeMoneyspaceClientEditDialog: (state: DialogsState) => {
      state.moneyspaceClientEdit = undefined
    },
    openMoneyspaceViewDialog: (state: DialogsState, action: PayloadAction<typeof state.moneyspaceView>) => {
      state.moneyspaceView = action.payload
    },
    closeMoneyspaceViewDialog: (state: DialogsState) => {
      state.moneyspaceView = undefined
    },
    openDocumentDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.documentDelete>) => {
      state.documentDelete = action.payload
    },
    closeDocumentDeleteDialog: (state: DialogsState) => {
      state.documentDelete = undefined
    },
    openDocumentApplyDialog: (state: DialogsState, action: PayloadAction<typeof state.documentApply>) => {
      state.documentApply = action.payload
    },
    closeDocumentApplyDialog: (state: DialogsState) => {
      state.documentApply = undefined
    },
    openDocumentApprovalFlowSettingDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.documentApprovalFlowSetting>
    ) => {
      state.documentApprovalFlowSetting = action.payload
    },
    closeDocumentApprovalFlowSettingDialog: (state: DialogsState) => {
      state.documentApprovalFlowSetting = undefined
    },
    openDocumentApproveDialog: (state: DialogsState, action: PayloadAction<typeof state.documentApprove>) => {
      state.documentApprove = action.payload
    },
    closeDocumentApproveDialog: (state: DialogsState) => {
      state.documentApprove = undefined
    },
    openDocumentBulkApproveDialog: (state: DialogsState, action: PayloadAction<typeof state.documentBulkApprove>) => {
      state.documentBulkApprove = action.payload
    },
    closeDocumentBulkApproveDialog: (state: DialogsState) => {
      state.documentBulkApprove = undefined
    },
    openDocumentRejectDialog: (state: DialogsState, action: PayloadAction<typeof state.documentReject>) => {
      state.documentReject = action.payload
    },
    closeDocumentRejectDialog: (state: DialogsState) => {
      state.documentReject = undefined
    },
    openDocumentCopyDialog: (state: DialogsState, action: PayloadAction<typeof state.documentCopy>) => {
      state.documentCopy = action.payload
    },
    closeDocumentCopyDialog: (state: DialogsState) => {
      state.documentCopy = undefined
    },
    openDocumentCsvImportItemPreviewDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.documentCsvImportItemPreview>
    ) => {
      state.documentCsvImportItemPreview = action.payload
    },
    closeDocumentCsvImportItemPreviewDialog: (state: DialogsState) => {
      state.documentCsvImportItemPreview = undefined
    },
    openDocumentDiffDialog: (state: DialogsState, action: PayloadAction<typeof state.documentDiff>) => {
      state.documentDiff = action.payload
    },
    closeDocumentDiffDialog: (state: DialogsState) => {
      state.documentDiff = undefined
    },
    openDocumentSearchConditionListDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.documentSearchConditionList>
    ) => {
      state.documentSearchConditionList = action.payload
    },
    closeDocumentSearchConditionListDialog: (state: DialogsState) => {
      state.documentSearchConditionList = undefined
    },
    openDocumentSearchConditionFormDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.documentSearchConditionForm>
    ) => {
      state.documentSearchConditionForm = action.payload
    },
    closeDocumentSearchConditionFormDialog: (state: DialogsState) => {
      state.documentSearchConditionForm = undefined
    },
    openDocumentSearchConditionDeleteDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.documentSearchConditionDelete>
    ) => {
      state.documentSearchConditionDelete = action.payload
    },
    closeDocumentSearchConditionDeleteDialog: (state: DialogsState) => {
      state.documentSearchConditionDelete = undefined
    },
    openTransactionCreateDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionCreate>) => {
      state.transactionCreate = action.payload
    },
    closeTransactionCreateDialog: (state: DialogsState) => {
      state.transactionCreate = undefined
    },
    openTransactionCancelDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionCancel>) => {
      state.transactionCancel = action.payload
    },
    closeTransactionCancelDialog: (state: DialogsState) => {
      state.transactionCancel = undefined
    },
    openTransactionDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionDelete>) => {
      state.transactionDelete = action.payload
    },
    closeTransactionDeleteDialog: (state: DialogsState) => {
      state.transactionDelete = undefined
    },
    openTransactionApplyDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionApply>) => {
      state.transactionApply = action.payload
    },
    closeTransactionApplyDialog: (state: DialogsState) => {
      state.transactionApply = undefined
    },
    openTransactionSubmitDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionSubmit>) => {
      state.transactionSubmit = action.payload
    },
    closeTransactionSubmitDialog: (state: DialogsState) => {
      state.transactionSubmit = undefined
    },
    openTransactionApproveDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionApprove>) => {
      state.transactionApprove = action.payload
    },
    closeTransactionApproveDialog: (state: DialogsState) => {
      state.transactionApprove = undefined
    },
    openTransactionBulkApproveDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionBulkApprove>
    ) => {
      state.transactionBulkApprove = action.payload
    },
    closeTransactionBulkApproveDialog: (state: DialogsState) => {
      state.transactionBulkApprove = undefined
    },
    openTransactionApprovalFlowSettingDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionApprovalFlowSetting>
    ) => {
      state.transactionApprovalFlowSetting = action.payload
    },
    closeTransactionApprovalFlowSettingDialog: (state: DialogsState) => {
      state.transactionApprovalFlowSetting = undefined
    },
    openTransactionsApprovalFlowSettingDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionsApprovalFlowSetting>
    ) => {
      state.transactionsApprovalFlowSetting = action.payload
    },
    closeTransactionsApprovalFlowSettingDialog: (state: DialogsState) => {
      state.transactionsApprovalFlowSetting = undefined
    },
    openTransactionRejectDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionReject>) => {
      state.transactionReject = action.payload
    },
    closeTransactionRejectDialog: (state: DialogsState) => {
      state.transactionReject = undefined
    },
    openTransactionPhaseDiffDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionPhaseDiff>) => {
      state.transactionPhaseDiff = action.payload
    },
    closeTransactionPhaseDiffDialog: (state: DialogsState) => {
      state.transactionPhaseDiff = undefined
    },
    openTransactionSearchConditionListDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionSearchConditionList>
    ) => {
      state.transactionSearchConditionList = action.payload
    },
    closeTransactionSearchConditionListDialog: (state: DialogsState) => {
      state.transactionSearchConditionList = undefined
    },
    openTransactionSearchConditionFormDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionSearchConditionForm>
    ) => {
      state.transactionSearchConditionForm = action.payload
    },
    closeTransactionSearchConditionFormDialog: (state: DialogsState) => {
      state.transactionSearchConditionForm = undefined
    },
    openTransactionSearchConditionDeleteDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionSearchConditionDelete>
    ) => {
      state.transactionSearchConditionDelete = action.payload
    },
    closeTransactionSearchConditionDeleteDialog: (state: DialogsState) => {
      state.transactionSearchConditionDelete = undefined
    },
    openTransactionCopyDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionCopy>) => {
      state.transactionCopy = action.payload
    },
    closeTransactionCopyDialog: (state: DialogsState) => {
      state.transactionCopy = undefined
    },
    openContractEditDialog: (state: DialogsState, action: PayloadAction<typeof state.contractEdit>) => {
      state.contractEdit = action.payload
    },
    closeContractEditDialog: (state: DialogsState) => {
      state.contractEdit = undefined
    },
    openContractDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.contractDelete>) => {
      state.contractDelete = action.payload
    },
    closeContractDeleteDialog: (state: DialogsState) => {
      state.contractDelete = undefined
    },
    openContractCopyDialog: (state: DialogsState, action: PayloadAction<typeof state.contractCopy>) => {
      state.contractCopy = action.payload
    },
    closeContractCopyDialog: (state: DialogsState) => {
      state.contractCopy = undefined
    },
    openAttachmentDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.attachmentDelete>) => {
      state.attachmentDelete = action.payload
    },
    closeAttachmentDeleteDialog: (state: DialogsState) => {
      state.attachmentDelete = undefined
    },
    openAttachmentsViewDialog: (state: DialogsState, action: PayloadAction<typeof state.attachmentsView>) => {
      state.attachmentsView = action.payload
    },
    closeAttachmentsViewDialog: (state: DialogsState) => {
      state.attachmentsView = undefined
    },
    openApprovalFlowSettingDialog: (state: DialogsState, action: PayloadAction<typeof state.approvalFlowSetting>) => {
      state.approvalFlowSetting = action.payload
    },
    closeApprovalFlowSettingDialog: (state: DialogsState) => {
      state.approvalFlowSetting = undefined
    },
    openApprovalFlowDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.approvalFlowDelete>) => {
      state.approvalFlowDelete = action.payload
    },
    closeApprovalFlowDeleteDialog: (state: DialogsState) => {
      state.approvalFlowDelete = undefined
    },
    openTransactionClientViewDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionClientView>
    ) => {
      state.transactionClientView = action.payload
    },
    closeTransactionClientViewDialog: (state: DialogsState) => {
      state.transactionClientView = undefined
    },
    openTransactionReleaseDialog: (state: DialogsState, action: PayloadAction<typeof state.transactionRelease>) => {
      state.transactionRelease = action.payload
    },
    closeTransactionReleaseDialog: (state: DialogsState) => {
      state.transactionRelease = undefined
    },
    openBillingPaidDialog: (state: DialogsState, action: PayloadAction<typeof state.billingPaid>) => {
      state.billingPaid = action.payload
    },
    closeBillingPaidDialog: (state: DialogsState) => {
      state.billingPaid = undefined
    },
    openBillingBulkPaidDialog: (state: DialogsState, action: PayloadAction<typeof state.billingBulkPaid>) => {
      state.billingBulkPaid = action.payload
    },
    closeBillingBulkPaidDialog: (state: DialogsState) => {
      state.billingBulkPaid = undefined
    },
    openTransactionCsvImportItemPreviewDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionCsvImportItemPreview>
    ) => {
      state.transactionCsvImportItemPreview = action.payload
    },
    closeTransactionCsvImportItemPreviewDialog: (state: DialogsState) => {
      state.transactionCsvImportItemPreview = undefined
    },
    openTransactionPdfPreviewDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.transactionPdfPreview>
    ) => {
      state.transactionPdfPreview = action.payload
    },
    closeTransactionPdfPreviewDialog: (state: DialogsState) => {
      state.transactionPdfPreview = undefined
    },
    openBillingPdfPreviewDialog: (state: DialogsState, action: PayloadAction<typeof state.billingPdfPreview>) => {
      state.billingPdfPreview = action.payload
    },
    closeBillingPdfPreviewDialog: (state: DialogsState) => {
      state.billingPdfPreview = undefined
    },
    openMessageCreateDialog: (state: DialogsState, action: PayloadAction<typeof state.messageCreate>) => {
      state.messageCreate = action.payload
    },
    closeMessageCreateDialog: (state: DialogsState) => {
      state.messageCreate = undefined
    },
    openMessageEditDialog: (state: DialogsState, action: PayloadAction<typeof state.messageEdit>) => {
      state.messageEdit = action.payload
    },
    closeMessageEditDialog: (state: DialogsState) => {
      state.messageEdit = undefined
    },
    openMessageDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.messageDelete>) => {
      state.messageDelete = action.payload
    },
    closeMessageDeleteDialog: (state: DialogsState) => {
      state.messageDelete = undefined
    },
    openReservedMessageDialog: (state: DialogsState, action: PayloadAction<typeof state.reservedMessage>) => {
      state.reservedMessage = action.payload
    },
    closeReservedMessageDialog: (state: DialogsState) => {
      state.reservedMessage = undefined
    },
    openJobWorkLocationSettingDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.jobWorkLocationSetting>
    ) => {
      state.jobWorkLocationSetting = action.payload
    },
    closeJobWorkLocationSettingDialog: (state: DialogsState) => {
      state.jobWorkLocationSetting = undefined
    },
    openJobImageSettingDialog: (state: DialogsState, action: PayloadAction<typeof state.jobImageSetting>) => {
      state.jobImageSetting = action.payload
    },
    closeJobImageSettingDialog: (state: DialogsState) => {
      state.jobImageSetting = undefined
    },
    openJobImageDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.jobImageDelete>) => {
      state.jobImageDelete = action.payload
    },
    closeJobImageDeleteDialog: (state: DialogsState) => {
      state.jobImageDelete = undefined
    },
    openJobOfferDeleteDialog: (state: DialogsState, action: PayloadAction<typeof state.jobOfferDelete>) => {
      state.jobOfferDelete = action.payload
    },
    closeJobOfferDeleteDialog: (state: DialogsState) => {
      state.jobOfferDelete = undefined
    },
    openJobOfferPublishedStatusChangeDialog: (
      state: DialogsState,
      action: PayloadAction<typeof state.jobOfferPublishedStatusChange>
    ) => {
      state.jobOfferPublishedStatusChange = action.payload
    },
    closeJobOfferPublishedStatusChangeDialog: (state: DialogsState) => {
      state.jobOfferPublishedStatusChange = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChatActions.postMessages.fulfilled, (state) => {
        state.messageCreate = undefined
      })
      .addCase(ChatActions.postReservedMessage.fulfilled, (state) => {
        state.messageCreate = undefined
      })
      .addCase(ChatActions.updateReservedMessage.fulfilled, (state) => {
        state.messageEdit = undefined
      })
      .addCase(ChatActions.deleteReservedMessage.fulfilled, (state) => {
        state.messageDelete = undefined
      })
      .addCase(CompanyActions.createUser.fulfilled, (state) => {
        state.userCreate = undefined
      })
      .addCase(CompanyActions.updateUser.fulfilled, (state) => {
        state.userEdit = undefined
      })
      .addCase(CompanyActions.deleteUser.fulfilled, (state) => {
        state.userDelete = undefined
      })
      .addCase(CompanyActions.createClient.fulfilled, (state) => {
        state.clientCreate = undefined
      })
      .addCase(CompanyActions.inviteUser.fulfilled, (state) => {
        state.clientCreate = undefined
      })
      .addCase(CompanyActions.updateClient.fulfilled, (state) => {
        state.clientEdit = undefined
      })
      .addCase(CompanyActions.deleteClient.fulfilled, (state) => {
        state.clientDelete = undefined
      })
      .addCase(CompanyActions.acceptClient.fulfilled, (state) => {
        state.clientAccept = undefined
      })
      .addCase(DocumentsActions.applyDocument.fulfilled, (state) => {
        state.documentApply = undefined
      })
      .addCase(DocumentsActions.updateApprovalFlow.fulfilled, (state) => {
        state.documentApprovalFlowSetting = undefined
      })
      .addCase(DocumentsActions.approveDocument.fulfilled, (state) => {
        state.documentApprove = undefined
        state.documentDiff = undefined
      })
      .addCase(DocumentsActions.bulkApproveDocument.fulfilled, (state) => {
        state.documentBulkApprove = undefined
      })
      .addCase(DocumentsActions.rejectDocument.fulfilled, (state) => {
        state.documentReject = undefined
        state.documentDiff = undefined
      })
      .addCase(DocumentsActions.saveDocument.fulfilled, (state) => {
        state.documentCopy = undefined
      })
      .addCase(DocumentsActions.updateTag.fulfilled, (state) => {
        state.tagEdit = undefined
      })
      .addCase(DocumentsActions.deleteTag.fulfilled, (state) => {
        state.tagDelete = undefined
      })
      .addCase(DocumentsActions.updateSearchParams.fulfilled, (state) => {
        state.documentSearchConditionList = undefined
      })
      .addCase(DocumentsActions.saveSearchCondition.fulfilled, (state) => {
        state.documentSearchConditionForm = undefined
      })
      .addCase(DocumentsActions.deleteSearchCondition.fulfilled, (state) => {
        state.documentSearchConditionDelete = undefined
      })
      .addCase(SessionActions.changePassword.fulfilled, (state) => {
        state.passwordChange = undefined
      })
      .addCase(SessionActions.changeEmail.fulfilled, (state) => {
        state.emailChange = undefined
      })
      .addCase(SessionActions.uploadUserImage.fulfilled, (state) => {
        state.userImageSetting = undefined
      })
      .addCase(MoneyspacesActions.createMoneyspaceFolder.fulfilled, (state) => {
        state.moneyspaceGroupCreate = undefined
      })
      .addCase(MoneyspacesActions.saveMoneyspaceFolder.fulfilled, (state) => {
        state.moneyspaceGroupEdit = undefined
      })
      .addCase(MoneyspacesActions.moveMoneyspaceFolder.fulfilled, (state) => {
        state.moneyspaceFolderMove = undefined
      })
      .addCase(MoneyspacesActions.deleteMoneyspaceFolder.fulfilled, (state) => {
        state.moneyspaceGroupDelete = undefined
      })
      .addCase(MoneyspacesActions.createMoneyspace.fulfilled, (state) => {
        state.moneyspaceCreate = undefined
      })
      .addCase(MoneyspacesActions.saveMoneyspace.fulfilled, (state) => {
        state.moneyspaceEdit = undefined
        state.moneyspaceClientEdit = undefined
      })
      .addCase(MoneyspacesActions.moveMoneyspace.fulfilled, (state) => {
        state.moneyspaceMove = undefined
      })
      .addCase(MoneyspacesActions.deleteMoneyspace.fulfilled, (state) => {
        state.moneyspaceDelete = undefined
      })
      .addCase(MoneyspacesActions.saveMembers.fulfilled, (state) => {
        state.moneyspaceMembersSetting = undefined
      })
      .addCase(DocumentsActions.deleteDocument.fulfilled, (state) => {
        state.documentDelete = undefined
      })
      .addCase(TransactionActions.createTransaction.fulfilled, (state) => {
        state.transactionCreate = undefined
      })
      .addCase(TransactionActions.cancelTransaction.fulfilled, (state) => {
        state.transactionCancel = undefined
      })
      .addCase(TransactionActions.deleteTransaction.fulfilled, (state) => {
        state.transactionDelete = undefined
      })
      .addCase(TransactionActions.applyTransaction.fulfilled, (state) => {
        state.transactionApply = undefined
      })
      .addCase(TransactionActions.approveTransaction.fulfilled, (state) => {
        state.transactionApprove = undefined
        state.transactionPhaseDiff = undefined
      })
      .addCase(TransactionActions.bulkApproveTransaction.fulfilled, (state) => {
        state.transactionBulkApprove = undefined
      })
      .addCase(TransactionActions.updateApprovalFlow.fulfilled, (state) => {
        state.transactionApprovalFlowSetting = undefined
      })
      .addCase(TransactionActions.updateApprovalFlows.fulfilled, (state) => {
        state.transactionsApprovalFlowSetting = undefined
      })
      .addCase(TransactionActions.rejectTransaction.fulfilled, (state) => {
        state.transactionReject = undefined
        state.transactionPhaseDiff = undefined
      })
      .addCase(TransactionActions.copyTransaction.fulfilled, (state) => {
        state.transactionCopy = undefined
      })
      .addCase(TransactionActions.saveContract.fulfilled, (state) => {
        state.contractEdit = undefined
      })
      .addCase(TransactionActions.deleteContract.fulfilled, (state) => {
        state.contractDelete = undefined
      })
      .addCase(TransactionActions.copyContract.fulfilled, (state) => {
        state.contractCopy = undefined
      })
      .addCase(TransactionActions.deleteAttachment.fulfilled, (state) => {
        state.attachmentDelete = undefined
      })
      .addCase(ApprovalFlowActions.saveFlow.fulfilled, (state) => {
        state.approvalFlowSetting = undefined
      })
      .addCase(ApprovalFlowActions.deleteFlow.fulfilled, (state) => {
        state.approvalFlowDelete = undefined
      })
      .addCase(TransactionActions.saveSearchParams.fulfilled, (state) => {
        state.transactionSearchConditionList = undefined
      })
      .addCase(TransactionActions.saveSearchCondition.fulfilled, (state) => {
        state.transactionSearchConditionForm = undefined
      })
      .addCase(TransactionActions.deleteSearchCondition.fulfilled, (state) => {
        state.transactionSearchConditionDelete = undefined
      })
      .addCase(TransactionActions.releaseTransaction.fulfilled, (state) => {
        state.transactionRelease = undefined
      })
      .addCase(TransactionActions.paidBilling.fulfilled, (state) => {
        state.billingPaid = undefined
      })
      .addCase(TransactionActions.bulkPaidBilling.fulfilled, (state) => {
        state.billingBulkPaid = undefined
      })
      .addCase(TransactionActions.submitTransaction.fulfilled, (state) => {
        state.transactionSubmit = undefined
      })
      .addCase(JobActions.updateWorkLocations.fulfilled, (state) => {
        state.jobWorkLocationSetting = undefined
      })
      .addCase(JobActions.deleteImage.fulfilled, (state) => {
        state.jobImageDelete = undefined
      })
      .addCase(JobActions.deleteOffer.fulfilled, (state) => {
        state.jobOfferDelete = undefined
      })
      .addCase(JobActions.updateOfferPublishedStatus.fulfilled, (state) => {
        state.jobOfferPublishedStatusChange = undefined
      })
  },
})

export default dialogsSlice
