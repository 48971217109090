import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import shortid from 'shortid'
import Decimal from 'decimal.js-light'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { TransactionFormParams } from './TransactionForm'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import NumberControl from '../../atoms/inputs/NumberControl'
import Label from '../../atoms/display/Label'
import useDocument from '../../../hooks/useDocument'
import { TransactionDetail } from '../../../types/transaction'
import { DetailPropConfig } from '../../../types/Company'

type TransactionDetailsFormProps = {
  detailPropConfig: DetailPropConfig
}

function ErrorLabel({ errors }: { errors?: FieldErrors<TransactionDetail> }) {
  if (errors) {
    const { message } = errors as unknown as { message: string }
    if (message) {
      return <Label text={message} color="error" variant="caption" />
    }
  }
  return <></>
}

export default function TransactionDetailsRSForm({ detailPropConfig }: TransactionDetailsFormProps) {
  const { calcDetailAmount } = useDocument()
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<TransactionFormParams>()
  const { fields, append } = useFieldArray({ control, name: 'details' })

  useEffect(() => {
    watch((value, { name }) => {
      if (name) {
        if (name.startsWith('details.') && value && value.details) {
          const nodes = name.split('.')
          const index = Number(nodes[1])
          const property = nodes[2]
          const detail = value.details[index]
          if (detail && (property === 'unitPrice' || property === 'quantity')) {
            const { unitPrice, quantity } = detail
            const amount = Math.floor(new Decimal(unitPrice ?? 0).times(quantity ?? 0).toNumber())
            setValue(`details.${index}.amount`, amount)
          }
        }
      }
    })
  }, [calcDetailAmount, setValue, watch])

  useEffect(() => {
    if (fields.length === 0) {
      append({
        id: shortid(),
        unitPrice: undefined,
        quantity: undefined,
        unit: '件',
        taxBucket: 1,
        amount: 0,
      })
    }
  }, [append, fields.length])

  return (
    <FlexColumn space={4}>
      {fields.map((detail, index) => (
        <FlexRow align="center" key={detail.id}>
          <FlexColumn>
            {errors.details && <ErrorLabel errors={errors.details[index]} />}
            <FlexRow wrap>
              <FormItem label={detailPropConfig.defaultProp.name} required={detailPropConfig.defaultProp.required}>
                <TextField register={register(`details.${index}.defaultProp`)} readonly />
              </FormItem>
              {detailPropConfig.customProp1.name.length > 0 && (
                <FormItem label={detailPropConfig.customProp1.name} required={detailPropConfig.customProp1.required}>
                  <TextField register={register(`details.${index}.customProp1`)} readonly />
                </FormItem>
              )}
              {detailPropConfig.customProp2.name.length > 0 && (
                <FormItem label={detailPropConfig.customProp2.name} required={detailPropConfig.customProp2.required}>
                  <TextField register={register(`details.${index}.customProp2`)} readonly />
                </FormItem>
              )}
            </FlexRow>
            <FlexRow wrap>
              <FormItem label="単価" required>
                <NumberControl
                  name={`details.${index}.unitPrice`}
                  control={control}
                  type="amount"
                  size="sm"
                  placeholder="100,000"
                  disabled
                />
              </FormItem>
              <FormItem label="数量" required>
                <NumberControl
                  name={`details.${index}.quantity`}
                  control={control}
                  size="xs"
                  placeholder="1"
                  disabled
                />
              </FormItem>
              <FormItem label="単位" required>
                <TextField register={register(`details.${index}.unit`)} size="xs" readonly />
              </FormItem>
              <FormItem label="金額">
                <NumberControl name={`details.${index}.amount`} control={control} disabled type="amount" />
              </FormItem>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      ))}
    </FlexColumn>
  )
}
