import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import usePage from '../../../hooks/usePage'
import { JobOfferAttributes } from '../../../types/job'
import { JobActions } from '../../../store/job'
import OfferForm from '../../../components/organisms/job/OfferForm'
import usePath from '../../../hooks/usePath'

export default function JobOfferNewPage() {
  const { changeTitle } = usePage()
  const dispatch = useDispatch()
  const path = usePath()

  useEffect(() => {
    changeTitle('求人管理', '新規求人登録')
  }, [changeTitle])

  const handleCreate = useCallback(
    (params: JobOfferAttributes) => {
      dispatch(
        JobActions.createOffer({
          offer: params,
          getRedirectPath: () => path.job(),
        })
      )
    },
    [dispatch, path]
  )

  return <OfferForm saveLabel="登録" onSave={handleCreate} />
}
