import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Component } from '../../types/react'
import { MoneyspacesActions } from '../../store/moneyspaces'
import { SessionActions } from '../../store/session'
import { SessionSelectors } from '../../store/session/selectors'
import { CompanyActions } from '../../store/company'
import { SystemSelectors } from '../../store/system/selectors'
import { ApprovalFlowActions } from '../../store/approval_flow'
import { MoneyspacesSelectors } from '../../store/moneyspaces/selectors'
import { SystemActions } from '../../store/system'

type DataProviderProps = {
  children: Component
}

export default function DataProvider({ children }: DataProviderProps) {
  const loggedIn = useSelector(SessionSelectors.loggedIn)
  const companyId = useSelector(SessionSelectors.companyId)
  const initializedSystem = useSelector(SystemSelectors.initialized)
  const initializedMoneyspace = useSelector(MoneyspacesSelectors.initialized)
  const dispatch = useDispatch()
  useEffect(() => {
    if (loggedIn) {
      dispatch(SessionActions.fetchMe())
      dispatch(MoneyspacesActions.fetchMoneyspaceFolders())
      dispatch(MoneyspacesActions.fetchMoneyspaces({}))
      dispatch(CompanyActions.fetchCompany())
      dispatch(CompanyActions.fetchUsers())
      dispatch(CompanyActions.fetchClients())
      dispatch(SystemActions.loadConsts())
    }
    const interval = setInterval(() => {
      if (loggedIn) {
        dispatch(MoneyspacesActions.fetchUnreadMessageCount({ silent: true }))
      }
    }, 60000)
    return () => clearInterval(interval)
  }, [dispatch, loggedIn])
  useEffect(() => {
    if (companyId) {
      dispatch(ApprovalFlowActions.fetchFlows({ companyId }))
    }
  }, [companyId, dispatch])
  if (!loggedIn || (initializedSystem && initializedMoneyspace)) {
    return <>{children}</>
  }
  return <></>
}
