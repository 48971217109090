import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { CompanyTypeRS } from '../../types/Company'

const companySelector = (state: StoreState) => state.company
export const CompanySelectors = {
  company: createSelector([companySelector], (state) => state.company),
  clients: createSelector([companySelector], (state) => state.clients),
  countInvitations: createSelector(
    [companySelector],
    (state) => state.clients.filter((client) => client.isInviter && !client.isAccepted).length
  ),
  users: createSelector([companySelector], (state) => state.users),
  bankSearchResults: createSelector([companySelector], (state) => state.bankSearchResults),
  bankBranchSearchResults: createSelector([companySelector], (state) => state.bankBranchSearchResults),
  inviteToken: createSelector([companySelector], (state) => state.inviteToken),
  invoiceLicenseCompanyName: createSelector([companySelector], (state) => state.invoiceLicenseCompanyName),
  membershipFeeName: createSelector([companySelector], (state) => state.company?.membershipFee?.name ?? '協力会費'),
  membershipFee: createSelector([companySelector], (state) => state.membershipFee),
  csvImportSetting: createSelector([companySelector], (state) => state.csvImportSetting),
  isRS: createSelector([companySelector], (state) => state.company?.type === CompanyTypeRS),
}
