import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import PaginationCountLabel from '../../molecules/display/PaginationCountLabel'
import Button from '../../atoms/inputs/Button'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Pagination from '../../atoms/navigation/Pagination'
import IconButton from '../../atoms/inputs/IconButton'
import { CopyIcon } from '../../atoms/display/Icons'
import { useTransactionCopyDialog, useTransactionCreateDialog } from '../../../hooks/useDialog'
import { TransactionActions } from '../../../store/transactions'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import useDay from '../../../hooks/useDay'
import useConsts from '../../../hooks/useConsts'
import { TransactionPhaseBilling, TransactionPhaseBillingDetail } from '../../../types/transaction'
import useRouter from '../../../hooks/useRouter'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { InvoiceLessPatternA, Moneyspace } from '../../../types/Moneyspace'
import TransactionStatusLabel from '../../molecules/display/TransactionStatusLabel'
import TransactionAmountView from '../../molecules/display/TransactionAmountView'
import Alert from '../../atoms/feedback/Alert'
import { SessionSelectors } from '../../../store/session/selectors'
import TransactionFavoriteButton from '../transaction/TransactionFavoriteButton'
import { User } from '../../../types/User'
import usePermission from '../../../hooks/usePermission'

type MoneyspaceTabProcessingViewProps = {
  moneyspaceId: string
  page: number
}

export function disabledAddButton(moneyspace?: Moneyspace, user?: User) {
  if (user === undefined || user.role === 3) {
    return true
  }
  if (moneyspace) {
    if (moneyspace.invoiceLessActionType === InvoiceLessPatternA) {
      return true
    }
    if (moneyspace.contractor && moneyspace.contractee) {
      return false
    }
    return moneyspace.client === null
  }
  return false
}

export default function MoneyspaceTabProcessingView({ moneyspaceId, page }: MoneyspaceTabProcessingViewProps) {
  const contractTransactionDialog = useTransactionCreateDialog()
  const transactions = useSelector(TransactionSelectors.transactions)
  const { transactionPermissions } = usePermission()
  const { find, isContractee, isRS } = useMoneyspace()
  const moneyspace = find(moneyspaceId)
  const user = useSelector(SessionSelectors.user)
  const { formatDate, fromNow } = useDay()
  const { phaseName } = useConsts()
  const { moneyspace: moneyspacePage, transaction: transactionPage } = useRouter()
  const dispatch = useDispatch()
  const transactionCopyDialog = useTransactionCopyDialog()

  useEffect(() => {
    dispatch(
      TransactionActions.fetchTransactions({
        moneyspaceId,
        page,
        filterType: 'active',
      })
    )
  }, [dispatch, moneyspaceId, page])

  const handleChangePage = useCallback(
    (newPage: number) => {
      moneyspacePage(moneyspaceId, 'processing', newPage)
      window.scrollTo(0, 0)
    },
    [moneyspaceId, moneyspacePage]
  )

  return (
    <FlexColumn>
      <FlexRow align="center" wrap>
        <Label text="取引一覧" variant="subtitle" />
        <PaginationCountLabel page={page} contents={transactions} />
        <Button
          size="sm"
          disabled={disabledAddButton(moneyspace, user)}
          onClick={() => contractTransactionDialog.open(moneyspaceId)}
        >
          追加
        </Button>
      </FlexRow>
      {moneyspace && moneyspace.invoiceLessActionType === InvoiceLessPatternA && (
        <>
          {isContractee(moneyspace, user) ? (
            <Alert
              severity="warning"
              title="このマネースペースに参加中の受注者は、事業者登録番号が登録されていないため、取引を追加できません"
            >
              <FlexColumn>
                <Label text="取引を追加する場合は、受注者側でmonectに事業者登録番号を登録する必要があります" />
              </FlexColumn>
            </Alert>
          ) : (
            <Alert severity="warning" title="貴社の事業者登録番号が登録されていないため、取引を追加できません">
              <FlexColumn>
                <Label text="取引を追加する場合は、下記の操作を行ってください" />
                <FlexColumn space={0}>
                  <Label text="1 右上のアイコンの「企業情報管理」をクリック" />
                  <Label text="2 自社の事業者登録番号（13桁）を入力して保存をクリック" />
                </FlexColumn>
              </FlexColumn>
            </Alert>
          )}
        </>
      )}
      <Table
        header={
          <TableRow>
            <TableCell header>取引名</TableCell>
            <TableCell header>現場住所</TableCell>
            <TableCell header size="sm2">
              金額
            </TableCell>
            <TableCell header size="xs">
              担当者
            </TableCell>
            <TableCell header size="xs">
              締め日
            </TableCell>
            <TableCell header size="xs">
              支払日
            </TableCell>
            <TableCell header size="xs">
              フェーズ
            </TableCell>
            <TableCell header size="xs">
              分割請求
            </TableCell>
            <TableCell header size="xs">
              ステータス
            </TableCell>
            <TableCell header size="xs">
              最終更新
            </TableCell>
            <TableCell header size="xs" />
          </TableRow>
        }
      >
        {transactions.results.map((transaction) => (
          <TableRow key={transaction.id} onClick={() => transactionPage(transaction.id)}>
            <TableCell>
              <FlexRow align="center">
                <TransactionFavoriteButton transaction={transaction} />
                {transaction.name}
              </FlexRow>
            </TableCell>
            <TableCell>{transaction.siteAddress}</TableCell>
            <TableCell size="sm2" align="right">
              <TransactionAmountView transaction={transaction} />
            </TableCell>
            <TableCell size="sm3" align="center">
              <FlexRow justify="center" wrap>
                {transaction.pic && <Label text={transaction.pic.name} />}
                {transaction.picSub && <Label text={transaction.picSub.name} />}
                {transaction.optional?.rsUserName && <Label text={transaction.optional.rsUserName} />}
                {!transaction.pic && !transaction.picSub && !transaction.optional?.rsUserName && <Label text="-" />}
              </FlexRow>
            </TableCell>
            <TableCell size="xs" align="center">
              {[TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase) ? (
                <Label text={transaction.closingDate ? formatDate(transaction.closingDate) : '-'} />
              ) : (
                <Label text="-" />
              )}
            </TableCell>
            <TableCell size="xs" align="center">
              {[TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase) ? (
                <Label text={transaction.paymentDate ? formatDate(transaction.paymentDate) : '-'} />
              ) : (
                <Label text="-" />
              )}
            </TableCell>
            <TableCell size="xs" align="center">
              {phaseName(transaction.currentPhase)}
            </TableCell>
            <TableCell size="xs" align="center">
              <>{transaction.times && <Label text={`第${transaction.times}回`} />}</>
            </TableCell>
            <TableCell size="xs" align="center">
              <TransactionStatusLabel transaction={transaction} />
            </TableCell>
            <TableCell size="xs" align="center">
              {fromNow(transaction.updatedAt)}
            </TableCell>
            <TableCell size="xs" align="center">
              <IconButton
                label="コピー"
                disabled={
                  transaction.times !== null || !transactionPermissions.copy(transaction, user) || isRS(moneyspace)
                }
                onClick={() => transactionCopyDialog.open(transaction.id, transaction.name)}
              >
                <CopyIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Pagination count={transactions.count} page={page} size={30} onChange={handleChangePage} />
    </FlexColumn>
  )
}
