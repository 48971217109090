import { JobOffer } from '../../../types/job'
import Label from '../../atoms/display/Label'
import { FlexColumn } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import JobOfferPublishedStatusView from './OfferPublishedStatusView'
import useDay from '../../../hooks/useDay'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import useRouter from '../../../hooks/useRouter'
import { useJobOfferDeleteDialog, useJobOfferPublishedStatusChangeDialog } from '../../../hooks/useDialog'

type Props = {
  offers: JobOffer[]
}

export default function OffersView({ offers }: Props) {
  const day = useDay()
  const { jobOfferEdit } = useRouter()
  const jobOfferDeleteDialog = useJobOfferDeleteDialog()
  const jobOfferPublishedStatusChangeDialog = useJobOfferPublishedStatusChangeDialog()

  if (offers.length === 0) {
    return <Label text="求人が登録されていません" />
  }
  return (
    <FlexColumn>
      <Table
        header={
          <TableRow>
            <TableCell header>求人名</TableCell>
            <TableCell header size="xs">
              ステータス
            </TableCell>
            <TableCell header size="xs">
              作成日時
            </TableCell>
            <TableCell header size="xs">
              更新日時
            </TableCell>
            <TableCell header size="xs" />
            <TableCell header size="xs" />
            <TableCell header size="xs" />
          </TableRow>
        }
      >
        {offers.map((offer) => (
          <TableRow key={offer.id}>
            <TableCell>
              <Label text={offer.name} />
            </TableCell>
            <TableCell size="xs">
              <FlexColumn align="center">
                <JobOfferPublishedStatusView offer={offer} />
                <Button size="sm" height="small" onClick={() => jobOfferPublishedStatusChangeDialog.open(offer.id)}>
                  変更
                </Button>
              </FlexColumn>
            </TableCell>
            <TableCell size="xs" align="center">
              <Label text={day.formatDateTime(offer.createdAt)} />
            </TableCell>
            <TableCell size="xs" align="center">
              <Label text={day.formatDateTime(offer.updatedAt)} />
            </TableCell>
            <TableCell size="xs" align="center">
              <Button size="sm">表示</Button>
            </TableCell>
            <TableCell size="xs" align="center">
              <Button size="sm" onClick={() => jobOfferEdit(offer.id)}>
                編集
              </Button>
            </TableCell>
            <TableCell size="xs">
              <Button size="sm" color="caution" onClick={() => jobOfferDeleteDialog.open(offer.id)}>
                削除
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </FlexColumn>
  )
}
