import { useSelector } from 'react-redux'
import { FlexRow } from '../../atoms/layout/Flex'
import { JobSelectors } from '../../../store/job/selectors'
import Image from '../../atoms/display/Image'

export default function ImageView() {
  const images = useSelector(JobSelectors.images)
  return (
    <FlexRow wrap space={2}>
      {images.length === 0 && <p>画像が登録されていません</p>}
      {images.map((image) => (
        <Image url={image.url} alt={image.fileName} width={150} height={94} />
      ))}
    </FlexRow>
  )
}
