import { createSearchParams, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import usePath from './usePath'
import { TransactionPhase } from '../types/transaction'

export default function useRouter() {
  const path = usePath()
  const navigate = useNavigate()
  return {
    go: useCallback((to: string) => navigate(to), [navigate]),
    back: useCallback(() => navigate(-1), [navigate]),
    login: useCallback(() => navigate(path.login()), [path, navigate]),
    registerContracteeEmail: useCallback(() => navigate(path.registerContracteeEmail()), [path, navigate]),
    registerContracteeUid: useCallback(() => navigate(path.registerContracteeUid()), [path, navigate]),
    registerContracteeName: useCallback(() => navigate(path.registerContracteeName()), [path, navigate]),
    registerContracteeTerms: useCallback(() => navigate(path.registerContracteeTerms()), [path, navigate]),
    registerContracteeCompleted: useCallback(() => navigate(path.registerContracteeCompleted()), [path, navigate]),
    registerContracteeInvitation: useCallback(() => navigate(path.registerContracteeInvitation()), [path, navigate]),
    registerContracteeAddress: useCallback(() => navigate(path.registerContracteeAddress()), [path, navigate]),
    registerContracteePhone: useCallback(() => navigate(path.registerContracteePhone()), [path, navigate]),
    registerContracteeInvoiceLicense: useCallback(
      () => navigate(path.registerContracteeInvoiceLicense()),
      [path, navigate]
    ),
    registerContracteeBank: useCallback(() => navigate(path.registerContracteeBank()), [path, navigate]),
    registerContracteeLine: useCallback(() => navigate(path.registerContracteeLine()), [path, navigate]),
    registerContracteeWellcome: useCallback(() => navigate(path.registerContracteeWellcome()), [path, navigate]),
    root: useCallback(() => navigate(path.root()), [path, navigate]),
    pendingTransactions: useCallback(
      (page?: number, sortColumn?: string, sortDirection?: string) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        if (sortColumn) {
          params.column = sortColumn
        }
        if (sortDirection) {
          params.direction = sortDirection
        }
        return navigate({
          pathname: path.pendingTransactions(),
          search: createSearchParams(params).toString(),
        })
      },
      [path, navigate]
    ),
    receivedTransactions: useCallback(
      (page?: number, sortColumn?: string, sortDirection?: string) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        if (sortColumn) {
          params.column = sortColumn
        }
        if (sortDirection) {
          params.direction = sortDirection
        }
        return navigate({
          pathname: path.receivedTransactions(),
          search: createSearchParams(params).toString(),
        })
      },
      [path, navigate]
    ),
    deliveredTransactions: useCallback(
      (page?: number, type?: number, code?: string, ms?: string) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        if (type) {
          params.type = String(type)
        }
        if (code) {
          params.code = code
        }
        if (ms) {
          params.ms = ms
        }
        navigate({ pathname: path.deliveredTransactions(), search: createSearchParams(params).toString() })
      },
      [path, navigate]
    ),
    unreadMessages: useCallback(
      (page?: number) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        return navigate({
          pathname: path.unreadMessages(),
          search: createSearchParams(params).toString(),
        })
      },
      [path, navigate]
    ),
    messages: useCallback(() => navigate({ pathname: path.messages() }), [path, navigate]),
    moneyspaceMessages: useCallback(
      (moneyspaceId: string) => navigate({ pathname: path.moneyspaceMessages(moneyspaceId) }),
      [path, navigate]
    ),
    paymentBillings: useCallback(
      (page?: number, sortColumn?: string, sortDirection?: string) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        if (sortColumn) {
          params.column = sortColumn
        }
        if (sortDirection) {
          params.direction = sortDirection
        }
        return navigate({
          pathname: path.paymentBillings(),
          search: createSearchParams(params).toString(),
        })
      },
      [path, navigate]
    ),
    activeTransactions: useCallback(
      (page?: number, sortColumn?: string, sortDirection?: string) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        if (sortColumn) {
          params.column = sortColumn
        }
        if (sortDirection) {
          params.direction = sortDirection
        }
        return navigate({
          pathname: path.activeTransactions(),
          search: createSearchParams(params).toString(),
        })
      },
      [path, navigate]
    ),
    billingTransactions: useCallback(
      (page?: number) => {
        const params: { [key: string]: string } = {}
        if (page) {
          params.page = String(page)
        }
        navigate({
          pathname: path.billingTransactions(),
          search: createSearchParams(params).toString(),
        })
      },
      [path, navigate]
    ),
    aggregation: useCallback(() => {
      navigate({
        pathname: path.aggregation(),
      })
    }, [path, navigate]),
    moneyspace: useCallback(
      (id: string, tab?: string, page?: number, billingId?: string) =>
        navigate(path.moneyspace(id, tab, page, billingId)),
      [path, navigate]
    ),
    document: useCallback(
      (moneyspaceId: string, contractId: string, documentId: string) =>
        navigate(path.document(moneyspaceId, contractId, documentId)),
      [path, navigate]
    ),
    documentEdit: useCallback(
      (moneyspaceId: string, contractId: string, documentId: string) =>
        navigate(path.documentEdit(moneyspaceId, contractId, documentId)),
      [path, navigate]
    ),
    transaction: useCallback(
      (transactionId: string, phase?: TransactionPhase) => {
        if (phase) {
          return navigate({
            pathname: path.transaction(transactionId),
            search: createSearchParams({ phase: String(phase) }).toString(),
          })
        }
        return navigate({ pathname: path.transaction(transactionId) })
      },
      [navigate, path]
    ),
    transactionEdit: useCallback(
      (transactionId: string) => navigate(path.transactionEdit(transactionId)),
      [navigate, path]
    ),
    transactionInstallment: useCallback(
      (transactionId: string) => navigate(path.transactionInstallment(transactionId)),
      [navigate, path]
    ),
    settingsCompany: useCallback(() => navigate(path.settingsCompany()), [path, navigate]),
    settingsUsers: useCallback(() => navigate(path.settingsUsers()), [path, navigate]),
    settingsClients: useCallback(() => navigate(path.settingsClients()), [path, navigate]),
    settingsProfile: useCallback(() => navigate(path.settingsProfile()), [path, navigate]),
    settingsApprovalFlows: useCallback(() => navigate(path.settingsApprovalFlows()), [path, navigate]),
    job: useCallback(() => navigate(path.job()), [path, navigate]),
    jobEdit: useCallback(() => navigate(path.jobEdit()), [path, navigate]),
    jobOfferNew: useCallback(() => navigate(path.jobOfferNew()), [path, navigate]),
    jobOfferEdit: useCallback((offerId: string) => navigate(path.jobOfferEdit(offerId)), [path, navigate]),
    search: useCallback(
      (tab?: string, page?: number) => {
        const params: { tab?: string; page?: string } = {}
        if (tab) {
          params.tab = tab
        }
        if (page) {
          params.page = String(page)
        }
        if (Object.keys(params).length !== 0) {
          return navigate({ pathname: path.search(), search: createSearchParams(params).toString() })
        }
        return navigate({ pathname: path.search() })
      },
      [path, navigate]
    ),
    toolsTransactionImporter: useCallback(() => navigate(path.toolsTransactionImporter()), [path, navigate]),
    factoring: useCallback(
      (moneyspaceId?: string) => {
        if (moneyspaceId) {
          return navigate({
            pathname: path.factoring(),
            search: createSearchParams({ ms: moneyspaceId }).toString(),
          })
        }
        return navigate({ pathname: path.factoring() })
      },
      [path, navigate]
    ),
  }
}
