import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import usePage from '../../../hooks/usePage'
import { JobOfferAttributes } from '../../../types/job'
import { JobActions } from '../../../store/job'
import OfferForm from '../../../components/organisms/job/OfferForm'
import usePath from '../../../hooks/usePath'
import { JobSelectors } from '../../../store/job/selectors'

export default function JobOfferEditPage() {
  const { changeTitle } = usePage()
  const path = usePath()
  const { offerId } = useParams()
  const offers = useSelector(JobSelectors.offers)
  const dispatch = useDispatch()
  const [offer, setOffer] = useState<JobOfferAttributes | undefined>()

  useEffect(() => {
    dispatch(JobActions.fetchOffers())
  }, [dispatch])

  useEffect(() => {
    changeTitle('求人管理', '求人編集')
  }, [changeTitle])

  useEffect(() => {
    if (!offerId) return
    setOffer(offers.find((item) => item.id === offerId))
  }, [offerId, offers])

  const handleUpdate = useCallback(
    (params: JobOfferAttributes) => {
      if (!offerId) return
      dispatch(
        JobActions.updateOffer({
          offerId,
          offer: params,
          getRedirectPath: () => path.job(),
        })
      )
    },
    [dispatch, offerId, path]
  )

  return <OfferForm offer={offer} saveLabel="更新" onSave={handleUpdate} />
}
