import { createSlice } from '@reduxjs/toolkit'
import { Job, JobArea, JobImage, JobOffer, JobOfferAttributes, WorkLocation } from '../../types/job'
import { createAsyncAction, GetRedirectPath } from '../actions'

export type JobState = {
  job: Job | null
  workLocations: WorkLocation[]
  areas: JobArea[]
  images: JobImage[]
  offers: JobOffer[]
}

export const initialJobState: JobState = {
  job: null,
  workLocations: [],
  areas: [],
  images: [],
  offers: [],
}

export const JobActions = {
  fetchJob: createAsyncAction<
    void,
    {
      job: Job | null
      workLocations: WorkLocation[]
      areas: JobArea[]
    }
  >('job/fetchJob', async (_, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const job = await jobRepository.loadJob(jobId)
      const workLocations = await jobRepository.loadWorkLocations(jobId)
      const areas = await jobRepository.loadAreas()
      return {
        job,
        workLocations,
        areas,
      }
    }
    return {
      job: null,
      workLocations: [],
      areas: [],
    }
  }),
  createJob: createAsyncAction<
    {
      establishedYear: number | null
      numberOfEmployees: number
      workType1: number
      workType1Detail: string
      workType2: number
      workType2Detail: string
      workType3: number
      workType3Detail: string
      siteUrl: string | null
      getRedirectPath: GetRedirectPath<void>
    },
    {
      job: Job | null
      redirectTo?: string
    }
  >('job/createJob', async (params, { jobRepository }) => {
    const job = await jobRepository.createJob(params)
    return {
      job,
      redirectTo: params.getRedirectPath(),
    }
  }),
  updateJob: createAsyncAction<
    {
      establishedYear: number | null
      numberOfEmployees: number
      workType1: number
      workType1Detail: string
      workType2: number
      workType2Detail: string
      workType3: number
      workType3Detail: string
      siteUrl: string | null
      getRedirectPath: GetRedirectPath<void>
    },
    {
      job: Job | null
      redirectTo?: string
    }
  >('job/updateJob', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const job = await jobRepository.updateJob(jobId, params)
      return {
        job,
        redirectTo: params.getRedirectPath(),
      }
    }
    return {
      job: null,
      redirectTo: params.getRedirectPath(),
    }
  }),
  updateWorkLocations: createAsyncAction<
    {
      areaIds: string[]
    },
    {
      workLocations: WorkLocation[]
    }
  >('job/updateWorkLocations', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const workLocations = await jobRepository.updateWorkLocations(jobId, params.areaIds)
      return {
        workLocations,
      }
    }
    return {
      workLocations: [],
    }
  }),
  fetchImages: createAsyncAction<
    void,
    {
      jobImages: JobImage[]
    }
  >('job/fetchImages', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const jobImages = await jobRepository.loadImages(jobId)
      return {
        jobImages,
      }
    }
    return {
      jobImages: [],
    }
  }),
  uploadImage: createAsyncAction<
    {
      file: File
    },
    {
      jobImage?: JobImage
      jobImages: JobImage[]
    }
  >('job/uploadImage', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const jobImage = await jobRepository.uploadImage(jobId, params.file)
      const jobImages = await jobRepository.loadImages(jobId)
      return {
        jobImage,
        jobImages,
      }
    }
    return {
      jobImages: [],
    }
  }),
  deleteImage: createAsyncAction<
    {
      id: string
    },
    {
      jobImages: JobImage[]
    }
  >('job/deleteImage', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      await jobRepository.deleteImage(jobId, params.id)
      const jobImages = await jobRepository.loadImages(jobId)
      return {
        jobImages,
      }
    }
    return {
      jobImages: [],
    }
  }),
  fetchOffers: createAsyncAction<
    void,
    {
      offers: JobOffer[]
    }
  >('job/fetchOffers', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const offers = await jobRepository.loadOffers(jobId)
      return {
        offers,
      }
    }
    return {
      offers: [],
    }
  }),
  createOffer: createAsyncAction<
    {
      offer: JobOfferAttributes
      getRedirectPath: GetRedirectPath<void>
    },
    {
      offer: JobOffer
      redirectTo?: string
    }
  >('job/createOffer', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const offer = await jobRepository.createOffer(jobId, params.offer)
      return {
        offer,
        redirectTo: params.getRedirectPath(),
      }
    }
    throw new Error('JobId is not found')
  }),
  updateOffer: createAsyncAction<
    {
      offerId: string
      offer: JobOfferAttributes
      getRedirectPath: GetRedirectPath<void>
    },
    {
      offer: JobOffer
      redirectTo?: string
    }
  >('job/updateOffer', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      const offer = await jobRepository.updateOffer(jobId, params.offerId, params.offer)
      return {
        offer,
        redirectTo: params.getRedirectPath(),
      }
    }
    throw new Error('JobId is not found')
  }),
  deleteOffer: createAsyncAction<
    {
      offerId: string
    },
    {
      offerId: string
    }
  >('job/deleteOffer', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      await jobRepository.deleteOffer(jobId, params.offerId)
    }
    return {
      offerId: params.offerId,
    }
  }),
  updateOfferPublishedStatus: createAsyncAction<
    {
      offerId: string
      publishedStatus: number
    },
    {
      offerId: string
      publishedStatus: number
    }
  >('job/updateOfferPublishedStatus', async (params, { jobRepository }, state) => {
    const jobId = state.company.company?.jobId
    if (jobId) {
      await jobRepository.updateOfferPublishedStatus(jobId, params.offerId, params.publishedStatus)
    }
    return {
      offerId: params.offerId,
      publishedStatus: params.publishedStatus,
    }
  }),
}

const jobSlice = createSlice({
  name: 'job',
  initialState: initialJobState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(JobActions.fetchJob.fulfilled, (state, action) => {
      state.job = action.payload.job
      state.workLocations = action.payload.workLocations
      state.areas = action.payload.areas
    })
    builder.addCase(JobActions.createJob.fulfilled, (state, action) => {
      state.job = action.payload.job
    })
    builder.addCase(JobActions.updateJob.fulfilled, (state, action) => {
      state.job = action.payload.job
    })
    builder.addCase(JobActions.updateWorkLocations.fulfilled, (state, action) => {
      state.workLocations = action.payload.workLocations
    })
    builder.addCase(JobActions.fetchImages.fulfilled, (state, action) => {
      state.images = action.payload.jobImages
    })
    builder.addCase(JobActions.uploadImage.fulfilled, (state, action) => {
      state.images = action.payload.jobImages
    })
    builder.addCase(JobActions.deleteImage.fulfilled, (state, action) => {
      state.images = action.payload.jobImages
    })
    builder.addCase(JobActions.fetchOffers.fulfilled, (state, action) => {
      state.offers = action.payload.offers
    })
    builder.addCase(JobActions.deleteOffer.fulfilled, (state, action) => {
      state.offers = state.offers.filter((offer) => offer.id !== action.payload.offerId)
    })
    builder.addCase(JobActions.updateOfferPublishedStatus.fulfilled, (state, action) => {
      state.offers = state.offers.map((offer) => {
        if (offer.id === action.payload.offerId) {
          return {
            ...offer,
            publishedStatus: action.payload.publishedStatus,
          }
        }
        return offer
      })
    })
  },
})

export default jobSlice
