import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import FormItem from '../../components/molecules/inputs/FormItem'
import TextField from '../../components/atoms/inputs/TextField'
import WorkTypeSelect from '../../components/organisms/job/WorkTypeSelect'
import NumberControl from '../../components/atoms/inputs/NumberControl'
import Label from '../../components/atoms/display/Label'
import Button from '../../components/atoms/inputs/Button'
import useValidationRule from '../../hooks/useValidationRule'
import Form from '../../components/atoms/inputs/Form'
import { JobActions } from '../../store/job'
import usePath from '../../hooks/usePath'
import { JobSelectors } from '../../store/job/selectors'
import useRouter from '../../hooks/useRouter'

type JobFormParams = {
  establishedYear?: number | null
  numberOfEmployees: number
  workType1: number
  workType1Detail: string
  workType2: number
  workType2Detail: string
  workType3: number
  workType3Detail: string
  siteUrl?: string | null
}

export default function JobEditPage() {
  const { changeTitle } = usePage()
  const rules = useValidationRule()
  const dispatch = useDispatch()
  const { job: jobPage } = usePath()
  const { job: toJob } = useRouter()
  const job = useSelector(JobSelectors.job)
  const [initialize, setInitialize] = useState(false)

  const form = useForm<JobFormParams>({
    resolver: yupResolver(rules.job),
    mode: 'onChange',
  })
  const { errors } = form.formState

  useEffect(() => {
    changeTitle('求人管理', '企業詳細編集')
  }, [changeTitle])

  useEffect(() => {
    dispatch(JobActions.fetchJob())
  }, [dispatch])

  useEffect(() => {
    if (job) {
      form.setValue('establishedYear', job.establishedYear)
      form.setValue('numberOfEmployees', job.numberOfEmployees)
      form.setValue('workType1', job.workType1)
      form.setValue('workType1Detail', job.workType1Detail)
      form.setValue('workType2', job.workType2)
      form.setValue('workType2Detail', job.workType2Detail)
      form.setValue('workType3', job.workType3)
      form.setValue('workType3Detail', job.workType3Detail)
      form.setValue('siteUrl', job.siteUrl)
    }
    setInitialize(true)
  }, [form, job])

  const handleSave = useCallback(
    (params: JobFormParams) => {
      if (job) {
        dispatch(
          JobActions.updateJob({
            establishedYear: params.establishedYear ?? null,
            numberOfEmployees: params.numberOfEmployees,
            workType1: params.workType1,
            workType1Detail: params.workType1Detail,
            workType2: params.workType2,
            workType2Detail: params.workType2Detail,
            workType3: params.workType3,
            workType3Detail: params.workType3Detail,
            siteUrl: params.siteUrl ?? null,
            getRedirectPath: jobPage,
          })
        )
      } else {
        dispatch(
          JobActions.createJob({
            establishedYear: params.establishedYear ?? null,
            numberOfEmployees: params.numberOfEmployees,
            workType1: params.workType1,
            workType1Detail: params.workType1Detail,
            workType2: params.workType2,
            workType2Detail: params.workType2Detail,
            workType3: params.workType3,
            workType3Detail: params.workType3Detail,
            siteUrl: params.siteUrl ?? null,
            getRedirectPath: jobPage,
          })
        )
      }
    },
    [dispatch, jobPage, job]
  )

  if (!initialize) {
    return null
  }

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleSave)}>
        <FlexColumn>
          <FormItem label="設立年">
            <FlexRow align="center">
              <NumberControl
                control={form.control}
                name="establishedYear"
                size="sm"
                error={errors.establishedYear?.message}
              />
              <Label text="年" />
            </FlexRow>
          </FormItem>
          <FormItem label="従業員数">
            <FlexRow align="center">
              <NumberControl
                control={form.control}
                name="numberOfEmployees"
                size="sm"
                error={errors.numberOfEmployees?.message}
              />
              <Label text="人" />
            </FlexRow>
          </FormItem>
          <FormItem label="第1工種">
            <FlexRow>
              <WorkTypeSelect name="workType1" error={errors.workType1?.message} />
              <TextField
                placeholder="詳細"
                size="lg"
                register={form.register('workType1Detail')}
                error={errors.workType1Detail?.message}
              />
            </FlexRow>
          </FormItem>
          <FormItem label="第2工種">
            <FlexRow>
              <WorkTypeSelect name="workType2" error={errors.workType2?.message} />
              <TextField
                placeholder="詳細"
                size="lg"
                register={form.register('workType2Detail')}
                error={errors.workType2Detail?.message}
              />
            </FlexRow>
          </FormItem>
          <FormItem label="第3工種">
            <FlexRow>
              <WorkTypeSelect name="workType3" error={errors.workType3?.message} />
              <TextField
                placeholder="詳細"
                size="lg"
                register={form.register('workType3Detail')}
                error={errors.workType3Detail?.message}
              />
            </FlexRow>
          </FormItem>
          <FormItem label="ホームページURL">
            <TextField size="lg" register={form.register('siteUrl')} error={errors.siteUrl?.message} />
          </FormItem>
          <FlexRow align="center">
            <Button onClick={toJob} color="secondary">
              キャンセル
            </Button>
            <Button type="submit">保存</Button>
          </FlexRow>
        </FlexColumn>
      </Form>
    </FormProvider>
  )
}
