import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useJobOfferPublishedStatusChangeDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import { JobActions } from '../../../store/job'
import { JobSelectors } from '../../../store/job/selectors'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'

export default function JobOfferPublishedStatusChangeDialog() {
  const { state, close } = useJobOfferPublishedStatusChangeDialog()
  const dispatch = useDispatch()
  const offers = useSelector(JobSelectors.offers)
  const jobOffer = offers.find((offer) => offer.id === state?.jobOfferId)

  const handleUpdateOfferPublishedStatus = useCallback(() => {
    if (state) {
      dispatch(
        JobActions.updateOfferPublishedStatus({
          offerId: state.jobOfferId,
          publishedStatus: jobOffer?.publishedStatus === 1 ? 0 : 1,
        })
      )
    }
  }, [dispatch, jobOffer?.publishedStatus, state])

  if (!jobOffer) {
    return null
  }
  return (
    <Dialog
      open={state !== undefined}
      title="求人の公開ステータス変更"
      width="xs"
      actions={
        <Button onClick={handleUpdateOfferPublishedStatus}>
          {jobOffer.publishedStatus === 0 ? '公開' : '非公開'}に変更
        </Button>
      }
      onClose={close}
    >
      <FlexColumn maxHeight="60vh">
        <Label text={jobOffer.name} variant="subtitle" />
        <Label text={`この求人を${jobOffer.publishedStatus === 0 ? '公開' : '非公開に'}しますか？`} />
      </FlexColumn>
    </Dialog>
  )
}
